module.exports = {
    pcregister: {
        p1: 'Sie haben ein Konto?',
        dl: 'Einloggen',
        zc: 'Anmeldung',
        p4: 'Konto',
        p5: 'Bitte geben Sie das Konto ein',
        p6: 'Bitte geben Sie das Passwort ein',
        p7: 'Bitte geben Sie das Bestätigung passwort ein',
        p8: 'Bitte geben Sie den Einladung scode ein',
        p9: 'Ich stimme zu',
        p10: 'Benutzer vereinbarung und Datenschutz richtlinie',
        p11: 'Mailbox',
        p12: 'Bitte geben Sie die E-Mail-Adresse ein',
        p13: 'Bitte geben Sie den E-Mail-Bestätigung scode ein',
        p14: 'Senden',
        p15: 'Jahr',
        p16: 'Monat',
        p17: 'Tag',
        p18: 'Stunden',
        p19: 'Minuten',
        p20: 'Sekunden'
    },
    pclogin: {
        l1: 'Kein Konto?',
        l2: 'Anmeldung',
        l3: 'Einloggen',
        l4: 'Bitte geben Sie den Benutzernamen ein',
        l5: 'Bitte geben Sie das Passwort ein',
        l6: 'Passwort vergessen',
        l7: 'Einloggen'
    },
    pccontract: {
        ss: 'Marktpreis',
        xj: 'Preis limit',
        gg: 'Hebel'
    },
    pcrapidly: {
        zf: 'Anstieg',
        dd: 'Bestellungen',
        bdsj: 'Ortszeit',
        ky: 'Verfügbar'
    },
    pchome: {
        global1: 'Die weltweit führende Plattform für den Handel mit digitalen Assets',
        global2: 'Es bietet Millionen von Nutzern in mehr als 100 Ländern sichere und glaubwürdige Dienste für den Handel mit digitalen Assets und das Asset Management.',
        global3: 'Sicher und zuverlässig',
        global4: 'Fünf Jahre Erfahrung in Finanz dienstleistungen für digitale Vermögens werte. Konzentrieren Sie sich auf verteilte Architekturen und Anti-DDoS-Angriffs systeme.',
        global5: 'Globales ökologisches Layout',
        global6: 'In vielen Ländern wurden lokalisierte Transaktion dienst zentren eingerichtet, um ein Blockchain-Ökosystem zu schaffen, das mehrere Formate integriert.',
        global7: 'Benutzer zuerst',
        global8: 'Einrichtung eines Voraus vergütung mechanismus und Einrichtung eines Anlegers chutz fonds.',
        whever1: 'Multi-Plattform-Terminal-Transaktionen jederzeit und überall',
        whever2: 'Unterstützt alle Geschäfts funktionen für iOS, Android und Windows.',
        register1: 'Registrieren Sie einen',
        register3: 'Das globale Konto beginnt mit dem Handel',
        register2: 'Registrieren Sie sich jetzt'
    },
    pcbottom: {
        box1: 'Weltweit führend',
        box2: 'Eines der besten Teams',
        box3: 'Mehr',
        box4: 'Über uns',
        box5: 'White paper',
        box6: 'Dienstleistungen',
        box7: 'Herunter laden',
        box8: 'Häufig gestellte Fragen',
        box9: 'Online-Kundendienst',
        box10: 'Vorschriften',
        box11: 'Benutzer vereinbarung',
        box12: 'Datenschutz',
        box13: 'Rechtliche Hinweise',
        box14: 'Kontakt'
    },
    pcAbout_us: {
        t1: 'Reise',
        t2: 'Wir, 7 Jahre Straße',
        t3: 'Seit unserer Geburt im Jahr 2015 haben wir uns darauf konzentriert, Blockchain-, Vollzeit-Technologie-und Kanal bau dienste zu bedienen.',
        t4: '2014-2017 erlebte eine Aufwärts phase der "Stabilität und des Aufstiegs". Das erste Halbjahr 2017-2018 war eine verrückte Phase des "Kaufs von Geld, um reich zu werden". Von der zweiten Hälfte des Jahres 2019 bis zum heutigen kalten Winter habe ich eine echte Fähigkeit entwickelt. Unzählige erfolgreiche Partner haben uns einen hochkarätigen Ruf in der Branche versch afft. Im Jahr 2022 werden wir die brandneue Plattform V3.0 haben und die neue Aktien gesellschaft Bit GoldEX gründen, die in mehr als 100 Ländern weltweit tätig ist.',
        t5: 'Wen wir suchen',
        t6: 'Von der Gründung bis heute',
        t7: 'Ich habe nach Dienst leistern gesucht, die Schwierigkeiten haben und den gleichen Geschmack wie ich haben, und in wenigen Jahren habe ich Probleme für Dutzende von Dienst leistern erfolgreich gelöst.',
        t8: 'Wenn Sie heute rational sind und einen guten Helfer finden möchten, der zusammenarbeiten kann, um Ihren eigenen Partner zu verwirklichen, können Sie vielleicht mit uns sprechen.',
        t9: 'Bitte glauben Sie: Die Person, die Sie suchen, hat Sie tatsächlich gesucht.',
        t10: 'Dienstleistungen',
        t11: 'Kein Verkäufer, das gleiche Geschäft',
        t12: 'Ein Freund führt Sie in das Geschäft ein, was zeigt, dass Sie in Ordnung sind. Kollegen führen Sie in das Geschäft ein, was zeigt, dass Sie einen guten Ruf haben. Wenn ein Kunde Ihnen das Geschäft vorstellt, beweist dies, dass Sie vertrauens würdig sind.',
        t13: 'Geist',
        t14: 'Wir sind Ziegel, wie können wir dorthin gehen?',
        t15: 'Unterschied liche Kunden haben unterschied liche Anforderungen. Unterschied liche Produkte haben unterschied liche Eigenschaften. Unterschied liche Zeiten, unterschied liche Ziele. Die Bedeutung unserer Existenz besteht darin, sich an die Konzepte, Systeme und Anforderungen verschiedener Dienst leister anzupassen und verschiedene Probleme zu lösen.',
        t16: 'Wir sind bereit, ein nachdenklicher und wirklicher Ziegelstein zu sein. Wo immer wir ihn brauchen, werden wir gehen.'
    },
    pcproblem: {
        p1: 'Häufig gestellte Fragen',
        p2: 'Warum ändert sich die Anzahl der konvertierten Assets?',
        p3: 'Die äquivalente Berechnung in einem Vermögens wert ist der Wert der derzeit gehaltenen digitalen Währung, die in USDT umgerechnet wird und sich aufgrund der Preis schwankungen des digitalen Vermögens werts ändert. Die Anzahl Ihrer digitalen Assets hat sich nicht geändert.',
        p4: 'Wie man mit dem Passwort für den Plattform handel umgeht',
        p5: 'Wenn Sie das Passwort für den Plattform handels fonds vergessen haben, gehen Sie bitte zu "Meine Einstellungen-Klicken, um das Geld kennwort festzulegen" oder wenden Sie sich an den Kundendienst, um es zurück zusetzen',
        p6: 'Wohin fließt das Geld nach dem Kauf der Transaktion?',
        p7: 'Nach Abschluss der Bestellung wird das Geld auf das Konto in der gesetzlichen Währung im Vermögens wert überwiesen. Wenn Sie Vermögens werte für den Kontrakt handel oder den Futures-Handel kaufen müssen, wenden Sie sich bitte an das Fonds übertragungs modul für die Überweisung.',
        p8: 'Warum Sie die Anzahl der Änderungen in Ihrem Spitznamen begrenzen sollten',
        p9: 'Aus Sicherheits gründen für Ihre Transaktionen beschränken wir derzeit, dass der Spitzname nur einmal geändert werden kann.',
        p10: 'Was bedeutet es, wenn es übertragen wird? Warum eine Übertragung erforderlich ist',
        p11: 'Um die Unabhängigkeit der Gelder zwischen Ihren Konten zu gewährleisten und Ihre Risiken angemessen zu kontrollieren, teilen wir hiermit die Konten der wichtigsten Handels module auf. Überweisungen beziehen sich auf den Prozess der Umwandlung von Vermögens werten zwischen Handels konten.',
        p12: 'Wie werden die Konto rechte des Benutzer vertrags berechnet?',
        p13: 'Netto wert des Vertrags kontos = Position Margin Position Floating Gewinn und Verlust des verfügbaren Betrags des Giro kontos',
        p14: 'Was ist der Wert der Stop-Loss-und Take-Profit-Einstellungen beim Öffnen einer Position? Wie soll es eingestellt werden?',
        p15: 'Die Gewinn-und Verlust obergrenze für Sie. Wenn der festgelegte Stop-Loss-Betrag erreicht ist, wird die Position automatisch geschlossen. Kann zur Risiko kontrolle beim Kauf eines Vertrags verwendet werden. Stellen Sie die Hälfte des Take-Profit-Volumens ein: Erhöhen Sie das Volumen x Menge x Hebel multi plikator, setzen Sie den Stop-Loss. Wir empfehlen Ihnen, diese entsprechend Ihrer tatsächlichen Vermögens situation einzurichten und das Risiko angemessen zu kontrollieren.',
        p16: 'Wie kann das Vertrags risiko reduziert werden?',
        p17: 'Sie können die verfügbaren Vermögens werte der verbleibenden Konten auf das Vertrags konto übertragen, indem Sie Geld überweisen, oder das Risiko verringern, indem Sie Ihre Positionen reduzieren',
        p18: 'Was bedeutet die Anzahl der offenen Vertrags positionen?',
        p19: 'Die Anzahl der offenen Positionen repräsentiert den Geldbetrag, den Sie für den Kauf erwarten. Beispiel: Wählen Sie auf der Eröffnungs seite des BTC/USDT-Handels paart, machen Sie mehr, der Eingabe preis beträgt 1000USDT und die Eingabe menge beträgt 10BTC, dh Sie erwarten, 10BTC zu kaufen, und der Stückpreis beträgt 1000USDT.',
        p20: 'Wie werden die Bearbeitungs gebühren im Vertrag berechnet?',
        p21: 'Bearbeitungs gebühr = Eröffnungs preis * Eröffnungs betrag * Bearbeitungs gebühr',
        p22: 'Vorsicht maßnahmen für die obligato rische Liquidation',
        p23: 'Der Grad des Risikos ist ein Maß für das Risiko Ihres Vermögens. Wenn das Risiko 100% entspricht, wird Ihre Position als geschlossen angesehen und das Risiko = (Marge/Eigenkapital des Vertrags kontos) * 100%. Um zu verhindern, dass Benutzer die Position überschreiten, legt das System das Risiko verhältnis fest und passt es an. Wenn der Risiko grad den vom System festgelegten Risiko wert erreicht, erzwingt das System die Liquidation. Beispiel: Das Anpassungs verhältnis der Systeme in stellungen beträgt 10%. Wenn Ihr Risiko größer oder gleich 90% ist, werden alle Ihre Positionen vom System zur Liquidation gezwungen.',
        p24: 'Was sind die Vertrags handels regeln?',
        p25: 'Art der Transaktion',
        p26: 'Die Handels typen werden in zwei Richtungen unterteilt: Long (Buy) und Short (Sell):',
        p27: 'Der Kauf von Long-Positionen (bullisch) bedeutet, dass Sie der Meinung sind, dass der aktuelle Index wahr schein lich steigen wird, und Sie möchten eine bestimmte Anzahl von Kontrakten zu dem von Ihnen festgelegten Preis oder aktuellen Marktpreis kaufen.',
        p28: 'Leerverkauf (bä risch) bedeutet, dass Sie glauben, dass der aktuelle Index fallen könnte, und eine bestimmte Anzahl neuer Kontrakte zu dem von Ihnen festgelegten Preis oder dem aktuellen Marktpreis verkaufen möchten.',
        p29: 'Bestell methode',
        p30: 'Begrenzte Bestellung: Sie müssen den Preis und die Menge der Bestellung angeben',
        p31: 'Marktpreis liste: Sie müssen nur die Bestell menge festlegen, der Preis ist der aktuelle Marktpreis',
        p32: 'Positionen halten',
        p33: 'Wenn der von Ihnen übermittelte Eröffnungs auftrag abgeschlossen ist, wird er als Position bezeichnet',
        p34: 'Probleme mit der Vertrags lieferung',
        p35: 'Der Plattform vertrag ist ein unbefristeter Vertrag und es gibt keine Lieferzeit. Solange das System die Bedingungen für die Nivellierung nicht erfüllt oder Sie die Position nicht manuell geschlossen haben, können Sie die Position dauerhaft halten. System abschluss',
        p36: '1: Erreichen Sie den eingestellten Wert von Take-Profit und Stop-Loss, und das System liquid iert die Position automatisch',
        p37: '2: Das Risiko ist zu hoch und das System erzwingt die Liquidation',
        p38: 'Was ist die Marge für den Kontrakt handel?',
        p39: 'Bei Kontrakt transaktionen können Benutzer auf der Grundlage des Vertrags preises und der Vertrags menge sowie des entsprechenden Hebel multi plikators an der Transaktion teilnehmen. Der Benutzer belegt beim Öffnen einer Position eine Marge. Je mehr Marge eröffnet wird, desto höher ist das Konto risiko.',
        p40: 'Was ist eine Limit-Order und eine Marktpreis-Order?',
        p41: 'Die Limit-Liste bezieht sich auf den Preis, zu dem Sie die Plattform für den Handel anvertrauen möchten, und die Marktpreis-Liste bezieht sich auf die direkte Beauftragung der Plattform für den Handel zum aktuellen Preis. In den Eröffnungs regeln hat die Marktpreis liste Vorrang vor der Limit liste. Wenn Sie sich für eine Preis limit liste entscheiden, eröffnen Sie bitte eine Position entsprechend dem aktuellen Marktpreis der Währung, um Verluste aufgrund eines unangemessenen Eröffnungs preises zu vermeiden.',
        p42: 'Was bedeutet die Risikos tufe des Vertrags handels?',
        p43: 'Das Risiko ist ein Risiko indikator auf Ihrem Vertrags konto. Der Risiko grad gleich 100% wird als Liquidation angesehen. Wir empfehlen, dass Sie bei einem Risiko von mehr als 50% vorsichtig sind, um Verluste durch Liquidation zu vermeiden. Sie können das Risiko verringern, indem Sie die verfügbaren Mittel des Vertrags vermögens auffüllen oder Ihre Position aufhellen.',
        p44: 'Warum ist ein Geldwechsel erforderlich?',
        p45: 'Der Zweck der Börse besteht darin, den reibungslosen Umlauf von Geldern in verschiedenen Währungen in Ihrem Vermögen zu ermöglichen, und die von Ihrem Futures-Konto erhaltenen QCCs können für den Handel frei in USDT umgewandelt werden. USDT in anderen Konten kann auch frei in QCC für den Handel eingelöst werden.',
        p46: 'Warum hat die andere Partei die Überweisung nicht rechtzeitig nach der Überweisung erhalten?',
        p47: 'Stellen Sie zunächst sicher, dass die Zahlungs methode, die Sie bei der Überweisung eingeben, genau der Zahlungs methode entspricht, die auf der Seite mit den Bestell details angezeigt wird. Dann bestätigen Sie bitte, ob Ihre Zahlungs methode eine sofortige Zahlung oder eine verspätete Zahlung ist. Wenden Sie sich schließlich an Ihre Bank und Ihr Zahlungs institut, um die System wartung oder andere Gründe zu erhalten.',
        p48: 'Wie kaufe ich USDT über die Plattform?',
        p49: 'Methode 1: Wählen Sie die Bestellung aus, die Sie über die Plattform-Handels liste kaufen und verkaufen möchten.',
        p50: 'Methode 2: Klicken Sie auf die Schaltfläche Veröffentlichen, um eine Bestellung oder einen Verkaufs auftrag für eine schnelle Transaktion gemäß Ihren Anforderungen zu veröffentlichen. Das System passt schnell zu den Kauf-und Verkaufs benutzern für Sie.',
        p51: 'Hinweis: Wenn die Bestellung nach 15 Minuten nicht bezahlt wird, storniert das System die Bestellung automatisch. Bitte zahlen Sie rechtzeitig. Die Bestellung wird am selben Tag mehr als dreimal storniert, und der Handel kann am selben Tag nicht erneut durchgeführt werden. Die Transaktion berechtigung wird am nächsten Tag wieder hergestellt.'
    },
    public: {
        zxkf: 'Online-Kundendienst',
        sure: 'Bestätigung',
        cancel: 'Stornierung',
        RefreshSucceeded: 'Refresh-Erfolg',
        noMoreData: 'Keine Daten mehr',
        pullingText: 'Dropdown zum Aktualisieren...',
        ReleaseText: 'Freigabe zum Aktualisieren...',
        loadingText: 'Laden...',
        noData: 'Keine Aufzeichnungen',
        xq: 'Details',
        submit: 'Bestätigung',
        kefu: 'Online-Kundendienst'
    },
    ChangeType: {
        Upper: 'System aufladung',
        Lower: 'System abhebung',
        Win: 'Gewinn',
        Bet: 'Investieren',
        BuyMining: 'Bergbau',
        CoinWin: 'Gewinn in neuer Währung',
        SettleMining: 'Der Minen auftraggeber kehrt zurück',
        GiveInterest: 'Bergbau einnahmen',
        BuyCoin: 'Die neue Währung hat die Lotterie nicht gewonnen',
        BuyCoinBack: 'Rückgabe des Singapur-Währungs abonnements',
        UsdtToBtc: 'Usdt zu Btc',
        UsdtToEth: 'Usdt zu Eth',
        BtcToUsdt: 'Btc zu Usdt',
        BtcToEth: 'Btc zu Eth',
        EthToUsdt: 'Eth zu Usdt',
        EthToBtc: 'Eth zu Btc',
        CoinFrozen: 'Einfrieren der neuen Währung',
        CoinThaw: 'Auftauen neuer Münzen',
        CoinCancel: 'Münz bestellung storniert',
        CoinBuy: 'Münzen kaufen',
        CoinBuyEntrust: 'Kauf kommission für Münzen',
        CoinSellEntrust: 'Münz verkaufs kommission',
        CoinSell: 'Münz verkauf',
        ContractBuy: 'Auftrags erteilung, Anzahlung abzug',
        ContractCharge: 'Auftrags erteilung, abzüglich Bearbeitungs gebühr',
        ContractCancelToBond: 'Stornierung des Vertrags und Rücker statt ung der Anzahlung',
        ContractCancelToCharge: 'Rücker statt ungs gebühr für Vertrags stornierung',
        ContractCloseProfit: 'Vertragliche Liquidation erträge'
    },
    trade: {
        title: 'Münzen',
        Buy: 'Kaufen',
        Sell: 'Verkaufen',
        pc: 'Position schließen',
        cb: 'Kosten',
        xj1: 'Aktueller Preis',
        yk: 'Gewinne und Verluste',
        xianjia: 'Preis limit',
        jiage: 'Preis',
        shuliang: 'Menge',
        keyong: 'Verfügbar',
        yonghuchicang: 'Benutzer positionen',
        fxlts: 'Risiko rate = Positions marge/Kunden kapital multi pli ziert mit 100%, wobei das Kunden kapital gleich den verfügbaren Mitteln auf dem Konto entspricht. Die anfängliche Handels marge weist einen variablen Gewinn und Verlust auf',
        fxl: 'Risiko rate',
        dqwt: 'Aktuelle Kommission',
        all: 'Alle',
        name: 'Name',
        shijian: 'Zeit',
        zyjg: 'Handel zum derzeit optimalen Preis',
        caozuo: 'Betrieb',
        xj: 'Preis limit',
        cc: 'Positionen halten',
        leixing: 'Typ',
        long: 'Mach mehr',
        short: 'Leerverkauf',
        sj: 'Marktpreis',
        jiaoyie: 'Transaktion volumen',
        orderNo: 'Bestellnummer',
        weituojia: 'Provisions preis',
        chengjiaojia: 'Transaktion preis',
        chengjiaoe: 'Umsatz',
        weituo: 'Provisions methode',
        qbwt: 'Alle in Auftrag gegeben',
        lswt: 'Historischer Auftrag',
        tips: 'Bitte geben Sie den richtigen Preis ein',
        tips1: 'Bitte geben Sie die Menge ein',
        Create: 'In Auftrag gegeben',
        Wait: 'Im Handel',
        Complete: 'Abgeschlossen',
        Cancel: 'Bestellung zurück gezogen',
        zsj: 'Stop-Loss-Preis',
        zyj: 'Take-Profit-Preis',
        cczsy: 'Gesamte in kommen aus Positionen',
        ykbl: 'Gewinn-und Verlust quote',
        bili: 'Verhältnis',
        jyss: 'Anzahl der Transaktionen Lose',
        bzj: 'Marge',
        sxf: 'Bearbeitungs gebühr',
        gg: 'Hebelwirkung Vielfache',
        yjpc: 'Ein-Klick-Liquidation',
        status: 'Zustand',
    },
    auth: {
        title: 'Authentifizierung der Identität',
        title1: 'Authentifizierung der Identität',
        low: 'Primär zertifizierung',
        high: 'Fort geschrittene Zertifizierung',
        success: 'Zertifizie rungen erfolgreich',
        tips: 'Bitte zuerst die primäre Zertifizierung',
        tips1: 'Bitte geben Sie Ihren richtigen Namen ein',
        tips2: 'Bitte geben Sie die ID-Nummer ein',
        tips3: 'Bitte laden Sie das vordere Foto des Personal ausweises hoch',
        tips4: 'Bitte laden Sie das Foto auf der Rückseite des Personal ausweises hoch',
        tips5: 'Bitte laden Sie ein Handfoto hoch',
        qrz: 'Gehen Sie zur Zertifizierung',
        shz: 'Audit',
        rzsb: 'Zertifizierung fehl geschlagen',
        userName: 'Richtiger Name',
        IDNumber: 'ID-Nummer',
        zmz: 'Vorder foto des Personal ausweises',
        bmz: 'Foto auf der Rückseite des Personal ausweises',
        scz: 'Handfoto'
    },
    share: {
        title: 'Mein Promotion-Code',
        cp: 'Adresse kopieren',
        success: 'Erfolgreiche Replikation'
    },
    property: {
        Contract: 'Münzen',
        Exchange: 'Vertrag in Sekunden',
        zzc: 'Bilanzsumme in',
        mhyzh: 'Zweite Vertrags konten',
        bbzh: 'Münz konto',
        available: 'Verfügbar',
        OnOrders: 'Einfrieren',
        Estimated: 'Konvert',
        cw: 'Finanz unterlagen',
        number: 'Menge',
        record: 'Aufzeichnung',
        Transfer: 'Transfer',
        time: 'Zeit',
        dhsl: 'Umtausch menge',
        tips: 'Bitte geben Sie die Einlösung menge ein',
        qbdh: 'Alle einlösen',
        dhhl: 'Wechselkurse',
        ky: 'Verfügbar',
        kd: 'Verfügbar',
        sxfbl: 'Verhältnis der Bearbeitungs gebühren',
        sxf: 'Bearbeitungs gebühr'
    },
    statusLang: {
        Review: 'Audit',
        Success: 'Erfolg',
        Fail: 'Scheitern'
    },
    with: {
        title: 'Auszahlung',
        history: 'Auszahlung datensatz',
        ldz: 'Ketten adresse',
        tbdz: 'Abo-Adresse',
        tips: 'Bitte geben Sie die Abo-Adresse ein',
        tips1: 'Bitte geben Sie die Anzahl der Münzen ein',
        tips2: 'Bitte geben Sie das Zahlungs kennwort ein',
        tbsl: 'Anzahl der Münzen',
        ky: 'Verfügbar',
        zjmm: 'Passwörter bezahlen',
        sxf: 'Bearbeitungs gebühr',
        dz: 'Geschätzte Ankunfts menge'
    },
    tabBar: {
        home: 'Startseite',
        Quotation: 'Zitate',
        Quotation2: 'Markt zitate',
        Trade: 'Münzen',
        Contract: 'Vertrag in Sekunden',
        assets: 'Vermögens werte',
        qhyy: 'Sprache wechseln',
        zyjb: 'Intelligente Speicherung',
        jsjy: 'Extremer Handel',
        jryw: 'Finanz geschäft',
        hyjy: 'Vertrags handel',
        bbjy: 'Münzen handel'
    },
    home: {
        UserName: 'Konto',
        kjmb: 'Schnell Münzen kaufen',
        score: 'Kredit punkte',
        zc: 'Unterstützung für BTC, USDT und mehr',
        scwk: 'Intelligente Speicherung',
        sgxb: 'SGD-Abonnement',
        aqkx: 'Sicher und glaubwürdig',
        aqkx1: 'Die weltweit führende Plattform für den Handel mit digitalen Assets',
        zfb: 'Erhöhung liste',
        dfb: 'Auswahl liste',
        mc: 'Name',
        hy: 'Vertrag',
        zxj: 'Letzter Preis',
        zdb: 'Auf und ab',
        sfyz: 'Authentifizierung der Identität',
        skfs: 'Zahlungs methode',
        share: 'Mein Share-Link',
        kjcb: 'Schnell aufladen',
        kjtb: 'Schnelle Abheben von Münzen',
        qbdz: 'Adresse der Brieftasche',
        bps: 'White paper',
        xgmm: 'Passwort ändern',
        xgzfmm: 'Ändern Sie das Zahlungs kennwort',
        gywm: 'Über uns',
        tcdl: 'Rückzug'
    },
    quotation: {
        title: 'Zitate',
        mc: 'Menge',
        zxj: 'Letzter Preis',
        zdb: 'Auf und ab',
        liang: 'Menge'
    },
    contract: {
        kcslfw: 'Anzahl der offenen Positionen:',
        gao: 'Hoch',
        di: 'Niedrig',
        liang: '24H Menge',
        placeholder: 'Bitte geben Sie die Menge ein',
        SuccessfulPurchase: 'Erfolg beim Kauf',
        smallMin: 'Minuten',
        hour: 'Stunden',
        dayMin: 'Tag',
        Trend: 'Zeit teilung',
        ccjl: 'Positions liste',
        ddjl: 'Auftrags protokoll',
        sl: 'Menge',
        gmj: 'Kaufpreis',
        dqj: 'Aktueller Preis',
        yjyk: 'Geschätzter Gewinn und Verlust',
        djs: 'Countdown',
        gbjg: 'Transaktion preis',
        sc: 'Bearbeitungs gebühr',
        pal: 'Gewinne und Verluste',
        mhy: 'Vertrag in Sekunden',
        sj: 'Zeit',
        yll: 'Rentabilität',
        mz: 'Aufkaufen',
        md: 'Kaufen und fallen',
        kcsl: 'Anzahl der offenen Positionen',
        kcsj: 'Eröffnungs zeit',
        zhye: 'Kontostand',
        qc: 'Stornierung'
    },
    editPassword: {
        title: 'Passwort ändern',
        jmm: 'Alte Passwörter',
        xmm: 'Neues Passwort',
        qrmm: 'Passwort bestätigen',
        placeholder: 'Alte Passwörter',
        placeholder1: 'Festlegen eines neuen Passworts',
        placeholder2: 'Bitte geben Sie das neue Passwort erneut ein',
        qrxf: 'Bestätigung'
    },
    withPass: {
        title: 'Abhebung passwort',
        jmm: 'Alte Passwörter',
        xmm: 'Neues Passwort',
        qrmm: 'Passwort bestätigen',
        placeholder: 'Alte Passwörter',
        placeholder1: 'Festlegen eines neuen Passworts',
        placeholder2: 'Bitte geben Sie das neue Passwort erneut ein',
        qrxf: 'Bestätigung',
        tips: 'Bitte geben Sie mindestens 6 alte Passwörter ein',
        tips1: 'Bitte geben Sie ein neues Passwort mit mindestens 6 Bits ein',
        tips2: 'Bitte geben Sie das richtige Passwort für die Bestätigung ein',
        tips3: 'Zweimal eingegebene neue Passwörter sind inkonsistent',
        tips4: 'Passwort erfolgreich festlegen',
        confirm: 'Bestätigung'
    },
    newBe: {
        title: 'SGD-Abonnement',
        title1: 'Mein Abonnement',
        scqx: 'Sperrfrist',
        day: 'Tag',
        sy: 'Verbleibende',
        sdsg: 'Aufzeichnung',
        sysl: 'Verbleibende Menge',
        gmsl: 'Anzahl der Einkäufe',
        dj: 'Stückpreis',
        tips: 'Bitte geben Sie die Kauf menge ein',
        gmzj: 'Gesamt kaufpreis',
        qbye: 'Geldbörse Guthaben',
        fmzj: 'Gesamt kaufpreis',
        gm: 'Kaufen',
        sgsj: 'Kaufzeit',
        Currency: 'Währung',
        Request: 'Anzahl der Anträge',
        Passing: 'Durch die Menge',
        MarketTime: 'Markte in führungs zeit',
        sqz: 'Bewerbungen'
    },
    register: {
        title: 'Anmeldung',
        lang: 'Sprache',
        send: 'Senden',
        Resend: 'Senden Sie erneut',
        Account: 'Konto',
        Password: 'Passwort',
        ConfirmPassword: 'Passwort bestätigen',
        InvitationCode: 'Einladung scode',
        btn: 'Anmeldung',
        amount: 'Konto',
        mailbox: 'Mailbox',
        haveAccount: 'Haben Sie ein Konto?',
        Login: 'Einloggen',
        placeholder: 'Bitte geben Sie die E-Mail-Adresse ein',
        placeholder0: 'Bitte geben Sie das Konto ein',
        placeholder1: 'Bitte geben Sie das Passwort ein',
        placeholder2: 'Bitte geben Sie das Bestätigung passwort ein',
        placeholder3: 'Bitte geben Sie den Einladung scode ein',
        placeholder4: 'Bitte geben Sie den E-Mail-Bestätigung scode ein',
        placeholder5: 'Bitte geben Sie die richtige Mailbox ein',
        success: 'Erfolgreiche Registrierung',
        message: 'Passwort mindestens 6 Bit',
        message1: 'Bestätigen Sie das Passwort mit mindestens 6 Stellen',
        message2: 'Zwei Mal stimmt das Passwort nicht überein',
        message3: 'Bitte lesen und überprüfen Sie die Vereinbarung',
        agree: 'Ich stimme zu',
        xy: 'Benutzer vereinbarung und Datenschutz richtlinie',
        qr: 'Bestätigung'
    },
    lockming: {
        title: 'Liste der gesperrten Lagerhäuser',
        wkb: 'Minen schatz graben',
        wkgjd: 'Einfacheres Mining',
        qsljl: 'Einfach, um Belohnungen zu erhalten',
        yjzsr: 'Geschätzter Gesamtumsatz',
        ck: 'Referenz annual isierung in den letzten 3 Tagen',
        tips: 'Zumindest',
        rlx: 'Tages zinsen',
        day: 'Tag',
        scqx: 'Sperrfrist',
        buy: 'Kaufen',
        zysl: 'Anzahl der Pfand',
        qbye: 'Geldbörse Guthaben',
        finished: 'Fertig',
        doing: 'In Arbeit...',
        sl: 'Menge',
        shouyilv: 'Rendite',
        ks: 'Zeit für den Start',
        js: 'Endzeit'
    },
    login: {
        title: 'Einloggen',
        lang: 'Sprache',
        Account: 'Konto',
        Password: 'Passwort',
        btn: 'Einloggen',
        placeholder1: 'Bitte geben Sie das Passwort ein',
        placeholder: 'Bitte geben Sie das Konto ein',
        fail: 'Bitte füllen Sie das vollständige Formular aus',
        ForgotPassword: 'Passwort vergessen',
        NoAccount: 'Noch kein Konto?',
        Registration: 'Anmeldung',
        server: 'Online-Kundendienst',
        jzmm: 'Merken Sie sich das Passwort',
        success: 'Erfolgreich angemeldet',
        Home: 'Startseite'
    },
    moneyAddr: {
        title: 'Adresse der Brieftasche',
        Currency: 'Währung',
        Address: 'Adresse',
        placeholder: 'Bitte wählen Sie',
        DepositAddress: 'Währungs adresse',
        WalletAddress: 'Adresse der Brieftasche',
        Date: 'Zeit',
        add: 'Brieftaschen adresse hinzufügen',
        ChainAddress: 'Ketten adresse',
        qrcode: 'Brieftasche QR-Code',
        submit: 'Bestätigung',
        tips: 'Bitte wählen Sie den Brieftaschen typ',
        tips1: 'Bitte wählen Sie den Ketten adress typ',
        tips2: 'Bitte geben Sie die Brieftaschen adresse ein',
        tips3: 'Bitte laden Sie den QR-Code der Brieftasche hoch',
        success: 'Erfolg'
    },
    recharge: {
        title: 'Aufladen',
        title1: 'Auflade details',
        title2: 'Einzelheiten zur Auszahlung',
        ls: 'Auflade details',
        td: 'Bitte wählen Sie den folgenden Auflade kanal',
        bc: 'Speichern Sie den QR-Code',
        address: 'Währungs adresse',
        copy: 'Adresse kopieren',
        num: 'Anzahl der aufladen den Münzen',
        Upload: 'Laden Sie einen Screenshot mit Zahlungs details hoch',
        Submit: 'Bestätigung',
        tip: 'Bitte geben Sie die Menge ein',
        tip1: 'Bitte laden Sie einen Screenshot hoch',
        copySuc: 'Erfolgreiche Replikation'
    },
    newChange: {
        num: 'Bitte geben Sie die Menge ein',
        psd: 'Bitte geben Sie das Passwort ein',
        hq: 'Zitate',
        bb: 'Hilfe zentrum',
        sec: 'Authentifizierung mit echtem Namen',
        ass: 'Vermögens werte',
        pool: 'Finanz management',
        newc: 'Über uns',
        rechange: 'Aufladen',
        with: 'Kunden service',
        help: 'Hilfe zentrum',
        a1: 'Finanzen',
        a2: 'Geld in Haft (USDT)',
        a3: 'Gewinn heute',
        a4: 'Kumulatives Einkommen',
        a5: 'In Arbeit',
        a6: 'Bestellungen',
        a7: 'Regeln',
        a8: 'Tägliche Rückkehr',
        a9: 'Einmaliges Limit',
        a10: 'Schleife',
        a11: 'Pecently (Täglicher Umsatz)',
        a12: 'Einzelne Einschränkungen',
        a13: 'Anzahl Hosting:',
        a14: 'Abonnieren',
        a15: 'Berechnung des Einkommens',
        a16: 'Das Bewegen von Ziegeln erfolgt über das Hosting von USDT auf der Plattform. Die Plattform verfügt über ein profession elles Team, das Arbitrage von Ziegeln durchführt...',
        a17: 'Ein Mitglied hat 10000U auf der Plattform eingezahlt und ein Vermögens verwaltungs produkt mit einer Laufzeit von 5 Tagen und einer täglichen Rendite von 0,3% bis 0,5% ausgewählt. Tägliches Maximum: 10000U x 0,5% = 50 U n, das Einkommen von 150 U-250U kann nach 5 Tagen erzielt werden, das Einkommen wird täglich ausgegeben und das gezahlte Einkommen kann jederzeit abgerufen werden. Der eingezahlte Kapital betrag ist abgelaufen, danach wird...',
        a18: 'Über pauschal ierten Schaden ersatz',
        a19: 'Wenn Sie den nicht abgelaufenen Kapital betrag übertragen möchten, wird ein pauschal ierter Schaden ersatz fällig. Pauschal ierter Schaden ersatz = Ausfall liquidation verhältnis * verbleibende Tage * Anzahl der Investitionen. Beispiel N: Die Standard abrechnung quote des Produkts beträgt 0,4%, die verbleibenden 3 Tage sind fällig und der Investitions betrag beträgt 1.000, der pauschal ierte Schaden ersatz = 0,4% * 3...',
        a20: 'Ich möchte mitmachen'
    },
    msgbank: {
        msgTitle: 'Message',
        noticeTitle: "Ankündigung",
        msgContent: 'Sie haben ungelesene Nachrichten',
        msgBtn: "Go check",
        bankCard: "Bankkarte",
        inputTips: 'Bitte vollständige Informationen eingeben',
        submitSuccess: 'erfolgreich eingereicht',
        CardUserName: "Name des Kontoinhabers",
        BankName: "Opening Bank",
        OpenBank: "Kontoeröffnungsstelle",
        CardNo: "Bankkartennummer",
        noBindBankmsg: 'Sie haben Ihre Bankkarte noch nicht verknüpft',
        noBindBankBtn: 'Unbind',
        modify: 'modifizieren',
    }
}