module.exports = {
    pcregister: {
        p1: 'Đã có tài khoản?',
        dl: 'Đăng nhập',
        zc: 'Đăng ký',
        p4: 'Tài khoản',
        p5: 'Vui lòng nhập tài khoản',
        p6: 'Vui lòng nhập mật khẩu',
        p7: 'Vui lòng nhập lại mật khẩu',
        p8: 'Vui lòng nhập mã mời',
        p9: 'Tôi đồng ý',
        p10: '《Thỏa thuận người dùng và Chính sách bảo mật》',
        p11: 'Email',
        p12: 'Vui lòng nhập email',
        p13: 'Vui lòng nhập mã xác minh email',
        p14: 'Gửi',
        p15: 'Năm',
        p16: 'Tháng',
        p17: 'Ngày',
        p18: 'Giờ',
        p19: 'Phút',
        p20: 'Giây'
    },
    pclogin: {
        l1: 'Không có tài khoản?',
        l2: 'Đăng ký',
        l3: 'Đăng nhập',
        l4: 'Vui lòng nhập tên người dùng',
        l5: 'Vui lòng nhập mật khẩu',
        l6: 'Quên mật khẩu',
        l7: 'Đăng nhập'
    },
    pccontract: {
        ss: 'Giá thị trường',
        xj: 'Giá giới hạn',
        gg: 'Đòn bẩy'
    },
    pcrapidly: {
        zf: 'Tăng trưởng',
        dd: 'Đơn hàng',
        bdsj: 'Thời gian địa phương',
        ky: 'Có sẵn'
    },
    pchome: {
        global1: 'Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới',
        global2: 'Cung cấp dịch vụ giao dịch và quản lý tài sản kỹ thuật số an toàn, đáng tin cậy cho hàng triệu người dùng tại hơn 100 quốc gia.',
        global3: 'An toàn và đáng tin cậy',
        global4: 'Năm năm kinh nghiệm trong dịch vụ tài chính tài sản kỹ thuật số. Tập trung vào kiến trúc phân tán và hệ thống chống tấn công DDoS.',
        global5: 'Bố trí hệ sinh thái toàn cầu',
        global6: 'Đã thiết lập các trung tâm dịch vụ giao dịch địa phương tại nhiều quốc gia, tạo ra hệ sinh thái blockchain kết hợp nhiều hình thái kinh doanh.',
        global7: 'Người dùng là trên hết',
        global8: 'Thiết lập cơ chế bồi thường trước, tạo quỹ bảo vệ nhà đầu tư.',
        whever1: 'Giao dịch đa nền tảng bất cứ lúc nào, bất cứ nơi đâu',
        whever2: 'Phủ sóng trên nhiều nền tảng như iOS, Android, Windows, hỗ trợ đầy đủ các chức năng kinh doanh.',
        register1: 'Đăng ký một tài khoản toàn cầu',
        register3: 'Bắt đầu giao dịch',
        register2: 'Đăng ký ngay'
    },
    pcbottom: {
        box1: 'Dẫn đầu toàn cầu',
        box2: 'Một trong những đội ngũ tốt nhất',
        box3: 'Thêm nữa',
        box4: 'Về chúng tôi',
        box5: 'Sách trắng',
        box6: 'Dịch vụ',
        box7: 'Tải xuống',
        box8: 'Câu hỏi thường gặp',
        box9: 'Chăm sóc khách hàng trực tuyến',
        box10: 'Quy định',
        box11: 'Thỏa thuận người dùng',
        box12: 'Chính sách bảo mật',
        box13: 'Tuyên bố pháp lý',
        box14: 'Liên hệ'
    },
    pcAbout_us: {
        t1: 'Hành trình',
        t2: 'Chúng tôi, con đường 7 năm',
        t3: 'Kể từ khi ra đời vào năm 2015, chúng tôi đã chuyên tâm phục vụ blockchain, chuyên trách kỹ thuật và dịch vụ xây dựng kênh.',
        t4: 'Giai đoạn từ 2014-2017 là thời kỳ "tăng trưởng ổn định", nửa đầu năm 2017-2018 là thời kỳ "phát tài bằng cách mua tiền", từ nửa cuối năm 2019 đến hôm nay là thời kỳ mùa đông, chúng tôi đã rèn luyện được khả năng thực sự. Rất nhiều đối tác thành công đã giúp chúng tôi, những người khiêm tốn, có danh tiếng cao trong ngành. Năm 2022, chúng tôi sẽ có nền tảng V3.0 hoàn toàn mới và thành lập công ty cổ phần mới BitGoldEX, hoạt động tại hơn 100 quốc gia trên thế giới.',
        t5: 'Chúng tôi đang tìm ai',
        t6: 'Từ khi thành lập đến hôm nay',
        t7: 'Luôn tìm kiếm các nhà cung cấp dịch vụ gặp khó khăn và có cùng tâm huyết, trong vài năm đã thành công giúp đỡ hàng chục nhà cung cấp dịch vụ giải quyết vấn đề.',
        t8: 'Trong thời đại lý trí này, nếu bạn muốn tìm một trợ thủ đắc lực, một đối tác đồng hành để tiếp tục tiến bước - có lẽ, bạn có thể tìm chúng tôi để trò chuyện.',
        t9: 'Hãy tin rằng: người bạn đang tìm kiếm, thực sự cũng đang tìm kiếm bạn.',
        t10: 'Dịch vụ',
        t11: 'Không có nhân viên kinh doanh, nhưng vẫn có việc',
        t12: 'Bạn bè giới thiệu kinh doanh cho bạn, chứng tỏ bạn là người tốt. Đối tác cùng ngành giới thiệu kinh doanh cho bạn, chứng tỏ danh tiếng của bạn không tệ. Khi khách hàng giới thiệu kinh doanh cho bạn, điều đó chứng tỏ bạn đáng tin cậy.',
        t13: 'Tinh thần',
        t14: 'Chúng tôi là một viên gạch, nơi nào cần là nơi đó chúng tôi đến',
        t15: 'Khách hàng khác nhau, yêu cầu khác nhau. Sản phẩm khác nhau, đặc tính khác nhau. Thời điểm khác nhau, mục tiêu khác nhau. Sự tồn tại của chúng tôi là để thích ứng với các khái niệm, hệ thống và yêu cầu khác nhau của các nhà cung cấp dịch vụ, giải quyết mọi vấn đề.',
        t16: 'Chúng tôi sẵn sàng trở thành một viên gạch có suy nghĩ, có tác dụng, nơi nào cần, chúng tôi sẽ đến đó.'
    },
    pcproblem: {
        p1: 'Câu hỏi thường gặp',
        p2: 'Tại sao số lượng tài sản sau khi chuyển đổi lại thay đổi?',
        p3: 'Giá trị tương đương của tài sản là giá trị của đồng tiền kỹ thuật số hiện có được quy đổi thành USDT, thay đổi theo biến động giá của tài sản kỹ thuật số. Số lượng tài sản kỹ thuật số của bạn không thay đổi.',
        p4: 'Phương pháp xử lý khi quên mật khẩu giao dịch trên nền tảng',
        p5: 'Nếu quên mật khẩu giao dịch trên nền tảng, vui lòng vào "Của tôi - Cài đặt - Bấm để cài đặt mật khẩu giao dịch" hoặc liên hệ với bộ phận chăm sóc khách hàng để đặt lại.',
        p6: 'Tiền đi đâu sau khi mua hàng?',
        p7: 'Sau khi hoàn thành đơn đặt hàng mua, tiền sẽ được chuyển vào tài khoản tiền pháp định trong tài sản. Nếu bạn cần mua tài sản để giao dịch hợp đồng hoặc giao dịch tương lai, vui lòng chuyển tiền qua mô-đun chuyển tiền.',
        p8: 'Tại sao cần giới hạn số lần thay đổi biệt danh?',
        p9: 'Vì lý do an toàn giao dịch của bạn, hiện tại chúng tôi giới hạn việc thay đổi biệt danh chỉ một lần.',
        p10: 'Khi chuyển tiền nghĩa là gì? Tại sao cần phải chuyển tiền?',
        p11: 'Để đảm bảo tính độc lập của các tài khoản trong tài khoản của bạn, thuận tiện cho việc kiểm soát rủi ro hợp lý, chúng tôi chia tài khoản của các mô-đun giao dịch chính. Chuyển tiền là quá trình chuyển đổi tài sản giữa các tài khoản giao dịch.',
        p12: 'Làm thế nào để tính toán quyền lợi của tài khoản hợp đồng?',
        p13: 'Giá trị ròng của tài khoản hợp đồng = Tiền bảo đảm vị thế + Lãi/lỗ dao động vị thế + Số tiền có sẵn trong tài khoản hiện tại',
        p14: 'Giá trị cắt lỗ và chốt lời khi mở vị thế là bao nhiêu? Nên cài đặt như thế nào?',
        p15: 'Lợi nhuận hoặc lỗ tối đa mà bạn thiết lập. Khi đạt đến số tiền cắt lỗ/chốt lời được thiết lập, hệ thống sẽ tự động đóng vị thế. Có thể dùng để kiểm soát rủi ro khi mua hợp đồng. Thiết lập một nửa số lượng chốt lời: Tăng thêm x Số lượng x Tỷ lệ đòn bẩy, thiết lập cắt lỗ. Chúng tôi khuyên bạn nên cài đặt theo tình hình tài sản thực tế của mình để kiểm soát rủi ro hợp lý.',
        p16: 'Làm thế nào để giảm rủi ro hợp đồng?',
        p17: 'Bạn có thể giảm rủi ro bằng cách chuyển số tài sản có sẵn trong tài khoản còn lại vào tài khoản hợp đồng, hoặc giảm vị thế nắm giữ.',
        p18: 'Số lượng mở vị thế hợp đồng nghĩa là gì?',
        p19: 'Số lượng vị thế chưa đóng đại diện cho số lượng tiền bạn dự định mua. Ví dụ: Trong trang mở cửa của cặp giao dịch BTC/USDT, chọn mua, nhập giá là 1000 USDT, nhập số lượng là 10 BTC, tức là: Bạn dự định mua 10 BTC, đơn giá là 1000 USDT.',
        p20: 'Làm thế nào để tính phí giao dịch trong hợp đồng?',
        p21: 'Phí giao dịch = Giá mở vị thế x Số lượng mở vị thế x Tỷ lệ phí giao dịch',
        p22: 'Lưu ý khi cưỡng chế đóng vị thế',
        p23: 'Mức độ rủi ro là chỉ số đo rủi ro tài sản của bạn. Khi mức độ rủi ro đạt 100%, vị thế của bạn bị coi là đã đóng vị thế, mức độ rủi ro = (Tiền bảo đảm vị thế / Giá trị tài sản của tài khoản hợp đồng) x 100%. Để ngăn chặn người dùng bị mất toàn bộ tài sản, hệ thống cài đặt tỷ lệ điều chỉnh mức độ rủi ro. Khi mức độ rủi ro đạt mức giá trị rủi ro do hệ thống đặt, hệ thống sẽ cưỡng chế đóng vị thế. Ví dụ: Hệ thống đặt tỷ lệ điều chỉnh là 10%, tức là khi mức độ rủi ro của bạn lớn hơn hoặc bằng 90%, tất cả các vị thế của bạn sẽ bị hệ thống cưỡng chế đóng vị thế.',
        p24: 'Quy tắc giao dịch hợp đồng là gì?',
        p25: 'Loại giao dịch',
        p26: 'Loại giao dịch chia thành hai hướng: Mua (Long) và Bán (Short):',
        p27: 'Mua (Long) tức là bạn nghĩ rằng chỉ số hiện tại có khả năng tăng và bạn muốn mua một số lượng nhất định theo giá bạn đặt hoặc giá thị trường hiện tại.',
        p28: 'Bán (Short) tức là bạn nghĩ rằng chỉ số hiện tại có khả năng giảm và bạn muốn bán một số lượng nhất định theo giá bạn đặt hoặc giá thị trường hiện tại.',
        p29: 'Cách đặt lệnh',
        p30: 'Đặt lệnh giới hạn: Bạn cần chỉ định giá và số lượng đặt lệnh',
        p31: 'Đặt lệnh thị trường: Bạn chỉ cần đặt số lượng, giá là giá thị trường hiện tại',
        p32: 'Vị thế nắm giữ',
        p33: 'Khi lệnh mở vị thế của bạn hoàn thành, được gọi là vị thế nắm giữ',
        p34: 'Vấn đề giao nhận hợp đồng',
        p35: 'Hợp đồng của nền tảng là hợp đồng vô thời hạn, không có thời gian giao nhận. Chỉ khi hệ thống không thỏa mãn điều kiện cưỡng chế đóng vị thế hoặc bạn không tự đóng vị thế, bạn có thể nắm giữ vị thế vô thời hạn. Hệ thống đóng vị thế',
        p36: '1: Khi đạt đến số tiền cắt lỗ/chốt lời thiết lập, hệ thống tự động đóng vị thế',
        p37: '2: Rủi ro quá cao, hệ thống cưỡng chế đóng vị thế',
        p38: 'Phí bảo đảm giao dịch hợp đồng là bao nhiêu?',
        p39: 'Trong giao dịch hợp đồng, người dùng có thể tham gia mua bán hợp đồng theo giá và số lượng hợp đồng, cũng như tỷ lệ đòn bẩy tương ứng. Khi mở vị thế sẽ sử dụng tiền bảo đảm. Số tiền bảo đảm mở vị thế càng nhiều, rủi ro tài khoản càng cao.',
        p40: 'Lệnh giới hạn và lệnh thị trường là gì?',
        p41: 'Lệnh giới hạn là lệnh bạn muốn nền tảng thực hiện giao dịch theo giá nào, lệnh thị trường là lệnh trực tiếp ủy thác nền tảng thực hiện giao dịch theo giá hiện tại. Trong quy tắc mở vị thế, lệnh thị trường ưu tiên hơn lệnh giới hạn. Nếu bạn chọn lệnh giới hạn, hãy mở vị thế theo giá thị trường hiện tại của đồng tiền, tránh mất mát do giá mở vị thế không hợp lý.',
        p42: 'Chỉ số rủi ro của giao dịch hợp đồng đại diện cho điều gì?',
        p43: 'Mức độ rủi ro là chỉ số rủi ro trong tài khoản hợp đồng của bạn. Mức độ rủi ro bằng 100% được coi là thanh lý. Chúng tôi khuyên khi mức độ rủi ro của bạn vượt quá 50%, bạn cần cẩn trọng mở vị thế, tránh mất mát do thanh lý. Bạn có thể giảm rủi ro bằng cách bổ sung số tiền có sẵn trong tài sản hợp đồng hoặc giảm vị thế.',
        p44: 'Tại sao cần phải đổi tiền?',
        p45: 'Mục đích của sàn giao dịch là giúp tài sản của bạn giữa các loại tiền khác nhau lưu thông một cách thuận lợi, QCC nhận được trong tài khoản tương lai có thể tự do đổi thành USDT để giao dịch. USDT trong các tài khoản khác cũng có thể tự do đổi thành QCC để giao dịch.',
        p46: 'Tại sao sau khi chuyển tiền, đối phương không nhận được ngay?',
        p47: 'Đầu tiên, hãy đảm bảo bạn đã điền phương thức thanh toán khi chuyển tiền giống hoàn toàn với phương thức hiển thị trong chi tiết đơn hàng. Sau đó hãy xác nhận phương thức thanh toán của bạn là thanh toán ngay hay thanh toán chậm. Cuối cùng, vui lòng liên hệ với ngân hàng và tổ chức thanh toán của bạn để kiểm tra bảo trì hệ thống hoặc các lý do khác.',
        p48: 'Làm thế nào để mua USDT qua nền tảng?',
        p49: 'Cách thứ nhất: Chọn đơn đặt hàng bạn muốn mua trong danh sách mua bán của nền tảng để thực hiện giao dịch.',
        p50: 'Cách thứ hai: Bấm nút phát hành, đăng đơn mua hoặc bán theo nhu cầu của bạn để thực hiện giao dịch nhanh chóng. Hệ thống sẽ nhanh chóng khớp người mua và người bán cho bạn.',
        p51: 'Lưu ý: Nếu đơn đặt hàng mua chưa được thanh toán sau 15 phút, hệ thống sẽ tự động hủy đơn hàng, vui lòng thanh toán kịp thời. Nếu hủy đơn hàng hơn 3 lần trong ngày, bạn không thể giao dịch lại trong ngày, quyền giao dịch sẽ được khôi phục vào ngày hôm sau.'
    },
    public: {
        zxkf: 'Hỗ trợ trực tuyến',
        sure: 'Xác nhận',
        cancel: 'Hủy bỏ',
        RefreshSucceeded: 'Làm mới thành công',
        noMoreData: 'Không còn dữ liệu',
        pullingText: 'Kéo xuống để làm mới ...',
        ReleaseText: 'Thả ra để làm mới ...',
        loadingText: 'Đang tải ...',
        noData: 'Không có ghi chép',
        xq: 'Chi tiết',
        submit: 'Xác nhận',
        kefu: 'Hỗ trợ trực tuyến'
    },
    ChangeType: {
        Upper: 'Nạp tiền hệ thống',
        Lower: 'Rút tiền hệ thống',
        Win: 'Lợi nhuận',
        Bet: 'Đầu tư',
        BuyMining: 'Đào coin',
        CoinWin: 'Lợi nhuận từ coin mới',
        SettleMining: 'Hoàn lại vốn đào coin',
        GiveInterest: 'Lợi nhuận đào coin',
        BuyCoin: 'Coin mới không trúng thưởng',
        BuyCoinBack: 'Hoàn lại đăng ký coin mới',
        UsdtToBtc: 'Chuyển Usdt sang Btc',
        UsdtToEth: 'Chuyển Usdt sang Eth',
        BtcToUsdt: 'Chuyển Btc sang Usdt',
        BtcToEth: 'Chuyển Btc sang Eth',
        EthToUsdt: 'Chuyển Eth sang Usdt',
        EthToBtc: 'Chuyển Eth sang Btc',
        CoinFrozen: 'Đóng băng coin mới trúng thưởng',
        CoinThaw: 'Giải phóng coin mới',
        CoinCancel: 'Hủy đơn hàng coin-coin',
        CoinBuy: 'Mua coin-coin',
        CoinBuyEntrust: 'Ủy thác mua coin-coin',
        CoinSellEntrust: 'Ủy thác bán coin-coin',
        CoinSell: 'Bán coin-coin',
        ContractBuy: 'Đặt lệnh hợp đồng, trừ tiền bảo đảm',
        ContractCharge: 'Đặt lệnh hợp đồng, trừ phí giao dịch',
        ContractCancelToBond: 'Hủy hợp đồng, hoàn lại tiền bảo đảm',
        ContractCancelToCharge: 'Hủy hợp đồng, hoàn lại phí giao dịch',
        ContractCloseProfit: 'Lợi nhuận từ việc đóng hợp đồng'
    },
    trade: {
        title: 'Giao dịch coin-coin',
        Buy: 'Mua',
        Sell: 'Bán',
        pc: 'Đóng vị thế',
        cb: 'Chi phí',
        xj1: 'Giá hiện tại',
        yk: 'Lợi nhuận',
        xianjia: 'Giá giới hạn',
        jiage: 'Giá',
        shuliang: 'Số lượng',
        keyong: 'Có sẵn',
        yonghuchicang: 'Vị thế của người dùng',
        fxlts: 'Tỷ lệ rủi ro = tiền bảo đảm vị thế / quyền lợi khách hàng x 100%, trong đó quyền lợi khách hàng = số tiền có sẵn trong tài khoản + tiền bảo đảm giao dịch ban đầu + lợi nhuận/lỗ chưa thực hiện',
        fxl: 'Tỷ lệ rủi ro',
        dqwt: 'Ủy thác hiện tại',
        all: 'Tất cả',
        name: 'Tên',
        shijian: 'Thời gian',
        zyjg: 'Giao dịch với giá tốt nhất hiện tại',
        caozuo: 'Hành động',
        xj: 'Giá giới hạn',
        cc: 'Vị thế',
        leixing: 'Loại',
        long: 'Mua vào',
        short: 'Bán khống',
        sj: 'Giá thị trường',
        jiaoyie: 'Số tiền giao dịch',
        orderNo: 'Số đơn hàng',
        weituojia: 'Giá ủy thác',
        chengjiaojia: 'Giá thực hiện',
        chengjiaoe: 'Số tiền thực hiện',
        weituo: 'Phương thức ủy thác',
        qbwt: 'Tất cả ủy thác',
        lswt: 'Ủy thác lịch sử',
        tips: 'Vui lòng nhập giá chính xác',
        tips1: 'Vui lòng nhập số lượng',
        Create: 'Đang ủy thác',
        Wait: 'Đang giao dịch',
        Complete: 'Đã đóng vị thế',
        Cancel: 'Đã hủy đơn hàng',
        zsj: 'Giá dừng lỗ',
        zyj: 'Giá chốt lời',
        cczsy: 'Lợi nhuận tổng cộng từ vị thế',
        ykbl: 'Tỷ lệ lợi nhuận/lỗ',
        bili: 'Tỷ lệ',
        jyss: 'Số lượng giao dịch',
        bzj: 'Tiền bảo đảm',
        sxf: 'Phí giao dịch',
        gg: 'Hệ số đòn bẩy',
        yjpc: 'Đóng vị thế một lần nhấn',
        status: 'Trạng thái',
    },
    auth: {
        title: 'Xác thực danh tính',
        title1: 'Xác thực danh tính',
        low: 'Xác thực cơ bản',
        high: 'Xác thực nâng cao',
        success: 'Xác thực thành công',
        tips: 'Vui lòng thực hiện xác thực cơ bản trước',
        tips1: 'Vui lòng nhập tên thật',
        tips2: 'Vui lòng nhập số chứng minh nhân dân',
        tips3: 'Vui lòng tải lên ảnh mặt trước của chứng minh nhân dân',
        tips4: 'Vui lòng tải lên ảnh mặt sau của chứng minh nhân dân',
        tips5: 'Vui lòng tải lên ảnh cầm chứng minh nhân dân',
        qrz: 'Đi xác thực',
        shz: 'Đang xét duyệt',
        rzsb: 'Xác thực không thành công',
        userName: 'Tên thật',
        IDNumber: 'Số chứng minh nhân dân',
        zmz: 'Ảnh mặt trước chứng minh nhân dân',
        bmz: 'Ảnh mặt sau chứng minh nhân dân',
        scz: 'Ảnh cầm chứng minh nhân dân'
    },
    share: {
        title: 'Mã giới thiệu của tôi',
        cp: 'Sao chép địa chỉ',
        success: 'Sao chép thành công'
    },
    property: {
        Contract: 'Coin-Coin',
        Exchange: 'Hợp đồng nhanh',
        zzc: 'Tài sản tổng cộng quy đổi',
        mhyzh: 'Tài khoản hợp đồng nhanh',
        bbzh: 'Tài khoản coin-coin',
        available: 'Có sẵn',
        OnOrders: 'Đã đóng băng',
        Estimated: 'Quy đổi',
        cw: 'Ghi chép tài chính',
        number: 'Số lượng',
        record: 'Ghi chép',
        Transfer: 'Chuyển khoản',
        time: 'Thời gian',
        dhsl: 'Số lượng đổi',
        tips: 'Vui lòng nhập số lượng đổi',
        qbdh: 'Đổi tất cả',
        dhhl: 'Tỷ giá đổi',
        ky: 'Có sẵn',
        kd: 'Có thể nhận',
        sxfbl: 'Tỷ lệ phí giao dịch',
        sxf: 'Phí giao dịch'
    },
    statusLang: {
        Review: 'Đang xét duyệt',
        Success: 'Thành công',
        Fail: 'Thất bại',
    },
    with: {
        title: 'Rút tiền',
        history: 'Ghi chép rút tiền',
        ldz: 'Địa chỉ chuỗi',
        tbdz: 'Địa chỉ rút tiền',
        tips: 'Vui lòng nhập địa chỉ rút tiền',
        tips1: 'Vui lòng nhập số lượng rút tiền',
        tips2: 'Vui lòng nhập mật khẩu thanh toán',
        tbsl: 'Số lượng rút tiền',
        ky: 'Có sẵn',
        zjmm: 'Mật khẩu thanh toán',
        sxf: 'Phí giao dịch',
        dz: 'Số lượng dự kiến nhận được'
    },
    tabBar: {
        home: 'Trang chủ',
        Quotation: 'Báo giá',
        Quotation2: 'Thị trường',
        Trade: 'Giao dịch coin-coin',
        Contract: 'Hợp đồng nhanh',
        assets: 'Tài sản',
        qhyy: 'Chuyển ngôn ngữ',
        zyjb: 'Lưu trữ thông minh',
        jsjy: 'Giao dịch nhanh',
        jryw: 'Dịch vụ tài chính',
        hyjy: 'Giao dịch hợp đồng',
        bbjy: 'Giao dịch coin-coin'
    },
    home: {
        UserName: 'Tài khoản',
        kjmb: 'Mua coin nhanh',
        score: 'Điểm tín dụng',
        zc: 'Hỗ trợ BTC, USDT, v.v.',
        scwk: 'Lưu trữ thông minh',
        sgxb: 'Đăng ký coin mới',
        aqkx: 'An toàn và đáng tin cậy',
        aqkx1: 'Nền tảng giao dịch tài sản số hàng đầu toàn cầu',
        zfb: 'Danh sách tăng giá',
        dfb: 'Danh sách giảm giá',
        mc: 'Tên',
        hy: 'Hợp đồng',
        zxj: 'Giá mới nhất',
        zdb: 'Biến động giá',
        sfyz: 'Xác thực danh tính',
        skfs: 'Phương thức thanh toán',
        share: 'Liên kết chia sẻ của tôi',
        kjcb: 'Nạp tiền nhanh',
        kjtb: 'Rút tiền nhanh',
        qbdz: 'Địa chỉ ví',
        bps: 'Sách trắng',
        xgmm: 'Đổi mật khẩu',
        xgzfmm: 'Đổi mật khẩu thanh toán',
        gywm: 'Giới thiệu về chúng tôi',
        tcdl: 'Đăng xuất'
    },
    quotation: {
        title: 'Thị trường',
        mc: 'Số lượng',
        zxj: 'Giá mới nhất',
        zdb: 'Biến động giá',
        liang: 'Khối lượng'
    },
    contract: {
        kcslfw: 'Phạm vi số lượng mở vị thế:',
        gao: 'Cao',
        di: 'Thấp',
        liang: 'Khối lượng 24H',
        placeholder: 'Vui lòng nhập số lượng',
        SuccessfulPurchase: 'Mua thành công',
        smallMin: 'Phút',
        hour: 'Giờ',
        dayMin: 'Ngày',
        Trend: 'Biểu đồ theo thời gian',
        ccjl: 'Danh sách vị thế',
        ddjl: 'Ghi chép đơn hàng',
        sl: 'Số lượng',
        gmj: 'Giá mua',
        dqj: 'Giá hiện tại',
        yjyk: 'Dự đoán lợi nhuận',
        djs: 'Đếm ngược',
        gbjg: 'Giá giao dịch',
        sc: 'Phí giao dịch',
        pal: 'Lợi nhuận',
        mhy: 'Hợp đồng nhanh',
        sj: 'Thời gian',
        yll: 'Tỷ lệ lợi nhuận',
        mz: 'Mua lên',
        md: 'Mua xuống',
        kcsl: 'Số lượng mở vị thế',
        kcsj: 'Thời gian mở vị thế',
        zhye: 'Số dư tài khoản',
        qc: 'Hủy bỏ'
    },
    editPassword: {
        title: 'Đổi mật khẩu',
        jmm: 'Mật khẩu cũ',
        xmm: 'Mật khẩu mới',
        qrmm: 'Xác nhận mật khẩu',
        placeholder: 'Mật khẩu cũ',
        placeholder1: 'Đặt mật khẩu mới',
        placeholder2: 'Nhập lại mật khẩu mới',
        qrxf: 'Xác nhận'
    },
    withPass: {
        title: 'Mật khẩu rút tiền',
        jmm: 'Mật khẩu cũ',
        xmm: 'Mật khẩu mới',
        qrmm: 'Xác nhận mật khẩu',
        placeholder: 'Mật khẩu cũ',
        placeholder1: 'Đặt mật khẩu mới',
        placeholder2: 'Nhập lại mật khẩu mới',
        qrxf: 'Xác nhận',
        tips: 'Vui lòng nhập mật khẩu cũ ít nhất 6 ký tự',
        tips1: 'Vui lòng nhập mật khẩu mới ít nhất 6 ký tự',
        tips2: 'Vui lòng nhập mật khẩu xác nhận chính xác',
        tips3: 'Mật khẩu mới nhập lại không khớp',
        tips4: 'Đặt mật khẩu thành công',
        confirm: 'Xác nhận'
    },
    newBe: {
        title: 'Đăng ký coin mới',
        title1: 'Đăng ký của tôi',
        scqx: 'Thời gian khóa',
        day: 'Ngày',
        sy: 'Còn lại',
        sdsg: 'Ghi chép',
        sysl: 'Số lượng còn lại',
        gmsl: 'Số lượng mua',
        dj: 'Đơn giá',
        tips: 'Vui lòng nhập số lượng mua',
        gmzj: 'Tổng giá mua',
        qbye: 'Số dư ví',
        fmzj: 'Tổng giá mua',
        gm: 'Mua',
        sgsj: 'Thời gian đăng ký',
        Currency: 'Loại coin',
        Request: 'Số lượng yêu cầu',
        Passing: 'Số lượng được phê duyệt',
        MarketTime: 'Thời gian niêm yết',
        sqz: 'Đang yêu cầu'
    },
    register: {
        title: 'Đăng ký',
        lang: 'Ngôn ngữ',
        send: 'Gửi',
        Resend: 'Gửi lại',
        Account: 'Tài khoản',
        Password: 'Mật khẩu',
        ConfirmPassword: 'Xác nhận mật khẩu',
        InvitationCode: 'Mã mời',
        btn: 'Đăng ký',
        amount: 'Tài khoản',
        mailbox: 'Email',
        haveAccount: 'Bạn đã có tài khoản?',
        Login: 'Đăng nhập',
        placeholder: 'Vui lòng nhập email',
        placeholder0: 'Vui lòng nhập tài khoản',
        placeholder1: 'Vui lòng nhập mật khẩu',
        placeholder2: 'Vui lòng nhập xác nhận mật khẩu',
        placeholder3: 'Vui lòng nhập mã mời',
        placeholder4: 'Vui lòng nhập mã xác nhận email',
        placeholder5: 'Vui lòng nhập email hợp lệ',
        success: 'Đăng ký thành công',
        message: 'Mật khẩu phải có ít nhất 6 ký tự',
        message1: 'Xác nhận mật khẩu phải có ít nhất 6 ký tự',
        message2: 'Hai mật khẩu không khớp',
        message3: 'Vui lòng đọc và đồng ý với các điều khoản',
        agree: 'Tôi đồng ý',
        xy: 'Thỏa thuận người dùng và Chính sách quyền riêng tư',
        qr: 'Xác nhận'
    },
    lockming: {
        title: 'Danh sách khóa',
        wkb: 'Kho báu khai thác',
        wkgjd: 'Khai thác đơn giản hơn',
        qsljl: 'Nhận phần thưởng dễ dàng',
        yjzsr: 'Dự đoán tổng lợi nhuận',
        ck: 'Tham khảo lãi suất hàng năm trong 3 ngày qua',
        tips: 'Ít nhất ',
        rlx: 'Lãi suất hàng ngày',
        day: 'Ngày',
        scqx: 'Thời gian khóa',
        buy: 'Mua',
        zysl: 'Số lượng đặt cọc',
        qbye: 'Số dư ví',
        finished: 'Hoàn tất',
        doing: 'Đang thực hiện...',
        sl: 'Số lượng',
        shouyilv: 'Tỷ lệ lợi nhuận',
        ks: 'Thời gian bắt đầu',
        js: 'Thời gian kết thúc'
    },
    login: {
        title: 'Đăng nhập',
        lang: 'Ngôn ngữ',
        Account: 'Tài khoản',
        Password: 'Mật khẩu',
        btn: 'Đăng nhập',
        placeholder1: 'Vui lòng nhập mật khẩu',
        placeholder: 'Vui lòng nhập tài khoản',
        fail: 'Vui lòng điền đầy đủ thông tin',
        ForgotPassword: 'Quên mật khẩu',
        NoAccount: 'Chưa có tài khoản?',
        Registration: 'Đăng ký',
        server: 'Dịch vụ khách hàng trực tuyến',
        jzmm: 'Nhớ mật khẩu',
        success: 'Đăng nhập thành công',
        Home: 'Trang chính'
    },
    moneyAddr: {
        title: 'Địa chỉ ví',
        Currency: 'Loại coin',
        Address: 'Địa chỉ',
        placeholder: 'Vui lòng chọn',
        DepositAddress: 'Địa chỉ nạp tiền',
        WalletAddress: 'Địa chỉ ví',
        Date: 'Thời gian',
        add: 'Thêm địa chỉ ví',
        ChainAddress: 'Địa chỉ chuỗi',
        qrcode: 'QR code ví',
        submit: 'Xác nhận',
        tips: 'Vui lòng chọn loại ví',
        tips1: 'Vui lòng chọn loại địa chỉ chuỗi',
        tips2: 'Vui lòng nhập địa chỉ ví',
        tips3: 'Vui lòng tải lên QR code ví',
        success: 'Thành công'
    },
    recharge: {
        title: 'Nạp tiền',
        title1: 'Chi tiết nạp tiền',
        title2: 'Chi tiết hiện tại',
        ls: 'Chi tiết nạp tiền',
        td: 'Vui lòng chọn kênh nạp tiền sau',
        bc: 'Lưu QR code',
        address: 'Địa chỉ nạp tiền',
        copy: 'Sao chép địa chỉ',
        num: 'Số lượng nạp tiền',
        Upload: 'Tải lên ảnh chụp chi tiết thanh toán',
        Submit: 'Xác nhận',
        tip: 'Vui lòng nhập số lượng',
        tip1: 'Vui lòng tải lên ảnh chụp màn hình',
        copySuc: 'Sao chép thành công'
    },
    newChange: {
        num: 'Vui lòng nhập số lượng',
        psd: 'Vui lòng nhập mật khẩu',
        hq: 'Thị trường',
        bb: 'Trung tâm hỗ trợ',
        sec: 'Xác thực danh tính',
        ass: 'Tài sản',
        pool: 'Đầu tư',
        newc: 'Về chúng tôi',
        rechange: 'Nạp tiền',
        with: 'Hỗ trợ khách hàng',
        help: 'Trung tâm hỗ trợ',
        a1: 'Tài chính',
        a2: 'Vốn đang giữ (USDT)',
        a3: 'Lợi nhuận hôm nay',
        a4: 'Lợi nhuận tích lũy',
        a5: 'Đang thực hiện',
        a6: 'Đơn hàng',
        a7: 'Quy tắc',
        a8: 'Lợi nhuận hàng ngày',
        a9: 'Giới hạn mỗi lần',
        a10: 'Vòng lặp',
        a11: 'Pecently (Thu nhập hàng ngày)',
        a12: 'Giới hạn cá nhân',
        a13: 'Số lượng quản lý:',
        a14: 'Đăng ký',
        a15: 'Tính toán lợi nhuận',
        a16: 'Kinh doanh chênh lệch là việc gửi USDT vào nền tảng, nền tảng có đội ngũ chuyên nghiệp thực hiện kinh doanh chênh lệch...',
        a17: 'Một thành viên gửi 10000U vào nền tảng, chọn sản phẩm đầu tư có thời hạn 5 ngày, tỷ lệ lợi nhuận hàng ngày từ 0.3% đến 0.5%. Lợi nhuận tối đa mỗi ngày: 10000U x 0.5% = 50 U, tức là sau 5 ngày có thể nhận được lợi nhuận từ 150U đến 250U, lợi nhuận được phát hàng ngày và có thể rút bất cứ lúc nào. Số vốn gửi vào đã hết hạn, sau đó sẽ là...',
        a18: 'Về phí vi phạm hợp đồng',
        a19: 'Nếu bạn muốn chuyển nhượng vốn chưa đến hạn, sẽ phát sinh phí vi phạm hợp đồng. Phí vi phạm hợp đồng = tỷ lệ thanh toán vi phạm * số ngày còn lại * số lần đầu tư. Ví dụ: tỷ lệ thanh toán vi phạm mặc định của sản phẩm là 0.4%, còn lại 3 ngày đến hạn, số tiền đầu tư là 1000, thì phí vi phạm = 0.4% * 3...',
        a20: 'Tôi muốn tham gia'
    },
    msgbank: {
        msgTitle: 'Tin nhắn',
        noticeTitle: 'Thông báo',
        msgContent: 'Bạn có tin nhắn chưa đọc',
        msgBtn: 'Xem ngay',
        bankCard: 'Thẻ ngân hàng',
        inputTips: 'Vui lòng nhập thông tin đầy đủ',
        submitSuccess: 'Gửi thành công',
        CardUserName: 'Tên chủ thẻ',
        BankName: 'Ngân hàng mở thẻ',
        OpenBank: 'Chi nhánh ngân hàng',
        CardNo: 'Số thẻ ngân hàng',
        noBindBankmsg: 'Bạn chưa liên kết thẻ ngân hàng',
        noBindBankBtn: 'Liên kết ngay',
        modify: 'Sửa'
    }
}