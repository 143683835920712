module.exports = {
    pcregister: {
        p1: 'Vous avez déjà un compte?',
        dl: 'Se connecter',
        zc: 'Inscription',
        p4: 'Compte',
        p5: 'Veuillez saisir le compte',
        p6: 'Veuillez saisir le mot de passe',
        p7: 'Veuillez saisir le mot de passe de confirmation',
        p8: 'Veuillez saisir le code d\'invitation',
        p9: 'Je suis d\'accord',
        p10: 'Accord utilisateur et politique de confidentialité',
        p11: 'Boîtes aux lettres',
        p12: 'Veuillez saisir la boîte aux lettres',
        p13: 'Veuillez saisir le code de vérification de la boîte aux lettres',
        p14: 'Envoyer',
        p15: 'Année',
        p16: 'Mois',
        p17: 'Jours',
        p18: 'Heures',
        p19: 'Minutes',
        p20: 'Secondes'
    },
    pclogin: {
        l1: 'Pas de compte?',
        l2: 'Inscription',
        l3: 'Se connecter',
        l4: 'Veuillez saisir le nom d\'utilisateur',
        l5: 'Veuillez saisir le mot de passe',
        l6: 'Mot de passe oublié',
        l7: 'Se connecter'
    },
    pccontract: {
        ss: 'Prix du marché',
        xj: 'Limite de prix',
        gg: 'Effet de levier'
    },
    pcrapidly: {
        zf: 'Augmentation',
        dd: 'Commandes',
        bdsj: 'Heure locale',
        ky: 'Disponible'
    },
    pchome: {
        global1: 'La principale plateforme de trading d\'actifs numériques au monde',
        global2: 'Il fournit des services de transaction et de gestion d\'actifs numériques sécurisés et crédibles à des millions d\'utilisateurs dans plus de 100 pays.',
        global3: 'Sécurité et fiabilité',
        global4: 'Cinq ans d\'expérience dans les services financiers d\'actifs numériques. Concentrez-vous sur les architectures distribuées et les systèmes anti-attaques DDoS.',
        global5: 'Disposition écologique mondiale',
        global6: 'Des centres de services de transaction localisés ont été créés dans de nombreux pays pour créer un écosystème de blockchain qui intègre plusieurs formats commerciaux.',
        global7: 'Les utilisateurs d\'abord',
        global8: 'Mettre en place un mécanisme de compensation préalable et créer un fonds de protection des investisseurs.',
        whever1: 'Trading de terminaux multi-plateformes à tout moment, n\'importe où',
        whever2: 'Il couvre plusieurs plates-formes pour iOS, Android et Windows et prend en charge les fonctions entièrement professionnelles.',
        register1: 'Inscrivez-vous pour un',
        register3: 'Les comptes mondiaux commencent à négocier',
        register2: 'Inscrivez-vous immédiatement'
    },
    pcbottom: {
        box1: 'Leader mondial',
        box2: 'Une des meilleures équipes',
        box3: 'Plus',
        box4: 'À propos de nous',
        box5: 'Livre blanc',
        box6: 'Services',
        box7: 'Télécharger',
        box8: 'FAQ',
        box9: 'Service à la clientèle en ligne',
        box10: 'Règlement',
        box11: 'Protocole utilisateur',
        box12: 'Politique de confidentialité',
        box13: 'Déclaration juridique',
        box14: 'Contacter'
    },
    pcAbout_us: {
        t1: 'Voyage',
        t2: 'Nous, 7 ans de route',
        t3: 'Depuis sa naissance en 2015, nous nous sommes plongés dans les services de blockchain, la technologie à plein temps et les services de construction de canaux.',
        t4: 'De 2014 à 2017, j\'ai connu une période à la hausse de "stabilité et de hausse". Le premier semestre de 2017-2018 a été une période folle de "acheter de l\'argent pour devenir riche". De la seconde moitié de 2019 à l\'hiver froid d\'aujourd\'hui, j\'ai développé une vraie capacité. D\'innombrables partenaires prospères nous ont donné une réputation très médiatisée dans l\'industrie. En 2022, nous aurons une toute nouvelle plate-forme V3.0 et créerons une nouvelle société par actions BitGoldEX, opérant dans plus de 100 pays à travers le monde.',
        t5: 'Qui cherchons-nous?',
        t6: 'De l\'établissement à aujourd\'hui',
        t7: 'Je suis à la recherche de fournisseurs de services qui ont des difficultés et qui ont le même goût, et ont réussi à résoudre des problèmes pour des dizaines de fournisseurs de services en quelques années.',
        t8: 'Dans la rationalité d\'aujourd\'hui, si vous voulez trouver une bonne aide qui peut travailler ensemble pour réaliser votre partenaire pour aller de l\'avant-peut-être, vous pouvez nous parler.',
        t9: 'Croyez: la personne que vous recherchez est en fait à votre recherche.',
        t10: 'Services',
        t11: 'Pas de vendeur, la même entreprise',
        t12: 'Un ami vous présente des affaires, ce qui montre que vous êtes bon. Les collègues vous présentent des affaires, ce qui montre que vous avez une bonne réputation. Lorsque les clients vous présentent des affaires, cela prouve que vous êtes digne de confiance.',
        t13: 'Esprit',
        t14: 'Nous sommes des briques, comment pouvons-nous aller',
        t15: 'Différents clients ont des exigences différentes. Différents produits ont des caractéristiques différentes. Différents moments, différents objectifs. Le sens de notre existence est de nous adapter aux concepts, aux systèmes et aux exigences des différents fournisseurs de services et de résoudre divers problèmes.',
        t16: 'Nous sommes prêts à faire une brique réfléchie et efficace, partout où il y a un besoin, nous nous dirigerons.'
    },
    pcproblem: {
        p1: 'FAQ',
        p2: 'Pourquoi le nombre d\'actifs convertis change-t-il?',
        p3: 'Le calcul de la valeur équivalente dans un actif est la valeur de la monnaie numérique actuellement détenue convertie en USDT, qui varie en raison des fluctuations de prix de l\'actif numérique. Le nombre de vos actifs numériques n\'a pas changé.',
        p4: 'Méthode de traitement par mot de passe des fonds oubliés pour les transactions sur plateforme',
        p5: 'Si vous oubliez le mot de passe des fonds de transaction de la plateforme, veuillez vous rendre sur "Mes-Paramètres-Cliquez sur Définir le mot de passe des fonds" ou contactez le service client pour réinitialiser',
        p6: 'Où est le flux des fonds après l\'achat de la transaction?',
        p7: 'Une fois le bon de commande terminé, les fonds entreront sur le compte en monnaie légale de l\'actif. Si vous devez acheter des actifs pour la négociation de contrats ou de contrats à terme, veuillez vous rendre au module de transfert de fonds pour le transfert.',
        p8: 'Pourquoi limiter le nombre de changements de surnom',
        p9: 'Pour des raisons de sécurité de votre transaction, nous limitons actuellement le surnom à modifier une seule fois.',
        p10: 'Que signifie le moment du transfert? Pourquoi le transfert est nécessaire',
        p11: 'Afin de garantir l\'indépendance des fonds entre vos comptes et de vous permettre de contrôler raisonnablement les risques, nous divisons par la présente les comptes des principaux modules de trading. Le transfert est le processus de conversion d\'actifs entre comptes de trading.',
        p12: 'Comment sont calculés les capitaux propres du compte du contrat de l\'utilisateur?',
        p13: 'Valeur nette du compte contractuel = position de marge flottante, montant disponible pour le compte courant de profit et de perte',
        p14: 'Quelle est la valeur des paramètres stop loss et stop profit lors de l\'ouverture d\'une position? Comment devrait-il être configuré?',
        p15: 'Le plafond de profit et de perte fixé pour vous. Lorsque le montant de stop-loss défini est atteint, le système liquide automatiquement la position. Peut être utilisé pour le contrôle des risques lors de l\'achat d\'un contrat. Définissez la moitié de l\'arrêt de profit comme suit: Augmentez la quantité x nombre x multiples de levier, définissez le stop loss. Nous vous recommandons de le configurer en fonction de vos actifs réels et de contrôler raisonnablement les risques.',
        p16: 'Comment réduire les risques contractuels?',
        p17: 'Vous pouvez transférer les actifs disponibles du compte restant sur le compte du contrat en transférant des fonds, ou réduire les risques en réduisant vos avoirs',
        p18: 'Que signifie le nombre de positions sous contrat?',
        p19: 'Le nombre de positions ouvertes représente le nombre de devises que vous attendez à acheter. Par exemple: Choisissez sur la page d\'ouverture de la paire de trading BTC/USDT, faites le long, entrez le prix de 1000USDT, entrez la quantité de 10BTC, c\'est-à-dire: vous prévoyez d\'acheter 10BTC avec un prix unitaire de 1000USDT.',
        p20: 'Comment sont calculés les frais de traitement dans le contrat?',
        p21: 'Frais de traitement = prix d\'ouverture * Nombre d\'entrepôts ouverts * Taux de frais de traitement',
        p22: 'Précautions pour la liquidation forcée',
        p23: 'Le degré de risque est une mesure du risque de vos actifs. Lorsque le risque est égal à 100%, votre position est considérée comme une liquidation, et le risque = (détenant des capitaux propres du compte de dépôt/contrat) * 100%. Afin d\'empêcher les utilisateurs de porter la position, le système est configuré pour ajuster le rapport de risque. Lorsque le niveau de risque atteint la valeur de risque définie par le système, le système forcera la liquidation. Par exemple: le taux de réglage des paramètres du système est de 10%, alors lorsque votre risque est supérieur ou égal à 90%, toutes vos positions seront obligées par le système à fermer.',
        p24: 'Quelles sont les règles de négociation de contrat?',
        p25: 'Type de transaction',
        p26: 'Les types de négociation sont divisés en deux directions: long (achat) et court (vente):',
        p27: 'Acheter haussier (haussier) signifie que vous pensez que l\'indice actuel est susceptible de monter et que vous voulez acheter un certain nombre d\'un certain nombre de contrats au prix que vous avez fixé ou au prix actuel du marché.',
        p28: 'La vente à découvert (baissière) signifie que vous pensez que l\'indice actuel pourrait baisser et que vous souhaitez vendre un certain nombre de nouveaux contrats au prix que vous avez fixé ou au prix actuel du marché.',
        p29: 'Méthode de commande',
        p30: 'Prix limite de commande: vous devez spécifier le prix et la quantité de commande',
        p31: 'Prix du marché: il vous suffit de définir la quantité de commande, le prix est le prix actuel du marché',
        p32: 'Entreposage',
        p33: 'Lorsque l\'instruction d\'ouverture que vous avez soumise est terminée, on parle de position',
        p34: 'Problèmes de livraison des contrats',
        p35: 'Le contrat de plate-forme est un contrat durable sans délai de livraison. Tant que le système ne remplit pas la condition de nivellement fort ou que vous ne liquidez pas manuellement, vous pouvez conserver votre position en permanence. Système de liquidation',
        p36: '1: Atteindre la valeur de réglage d\'arrêt de profit et d\'arrêt de perte, le système est automatiquement fermé',
        p37: '2: Le risque est trop élevé, le système force la liquidation',
        p38: 'Quelle est la marge pour les transactions contractuelles?',
        p39: 'Dans les transactions contractuelles, les utilisateurs peuvent participer à l\'achat et à la vente du contrat en fonction du prix et de la quantité du contrat, ainsi que du multiple de levier correspondant. L\'utilisateur occupe la marge lors de l\'ouverture d\'une position. Plus la marge d\'ouverture est élevée, plus le risque de compte est élevé.',
        p40: 'Qu\'est-ce qu\'un ordre à prix limité et un ordre de marché?',
        p41: 'La limite de prix fait référence à la plate-forme de commande à quel prix vous attendez, et la liste de prix de marché fait référence à la plate-forme de commande directe pour négocier au prix actuel. Dans les règles d\'ouverture de position, l\'ordre de prix du marché a la priorité sur l\'ordre de limite de prix. Si vous choisissez une limite de prix, veuillez ouvrir une position de manière raisonnable en fonction du prix actuel du marché de la devise pour éviter les pertes dues au prix d\'ouverture déraisonnable.',
        p42: 'Que représente le niveau de risque des transactions contractuelles?',
        p43: 'Le risque est un indicateur de risque dans votre compte contractuel. Le degré de risque égal à 100% est considéré comme une liquidation. Nous vous recommandons d\'ouvrir votre position avec prudence lorsque votre risque dépasse 50% pour éviter les pertes dues à la liquidation. Vous pouvez réduire les risques en refinançant les fonds disponibles sur vos actifs contractuels ou en allégeant vos positions.',
        p44: 'Pourquoi un échange de devises est-il nécessaire?',
        p45: 'Le but de l\'échange est de permettre aux fonds de différentes devises de vos actifs de circuler en douceur, et le QCC obtenu sur le compte à terme peut être librement converti en USDT pour la négociation. Les USDT dans d\'autres comptes peuvent également être échangés librement contre QCC.',
        p46: 'Pourquoi l\'autre partie n\'a-t-elle pas reçu le transfert à temps après le transfert?',
        p47: 'Tout d\'abord, assurez-vous que le mode de paiement que vous avez rempli lors du transfert est exactement le même que celui indiqué sur la page des détails de la commande. Vérifiez ensuite si votre mode de paiement est un paiement immédiat ou en retard. Enfin, contactez votre banque et votre établissement de paiement pour la maintenance du système ou pour d\'autres raisons.',
        p48: 'Comment acheter USDT via la plateforme?',
        p49: 'Méthode 1: Choisissez l\'ordre que vous souhaitez acheter via la liste de vente de la plateforme.',
        p50: 'Méthode 2: Cliquez sur le bouton Publier pour publier des ordres d\'achat ou de vente selon vos besoins pour effectuer des transactions rapides. Le système fera rapidement correspondre les utilisateurs acheteurs et vendeurs pour vous.',
        p51: 'Remarque: Si le bon de commande n\'est toujours pas payé après 15 minutes, le système annule automatiquement la commande, veuillez payer à temps. La commande a été annulée plus de 3 fois le même jour, et la transaction n\'a pas pu être échangée le même jour, et l\'autorité de négociation a été rétablie le lendemain.'
    },
    public: {
        zxkf: 'Service à la clientèle en ligne',
        sure: 'Confirmation',
        cancel: 'Annulation',
        RefreshSucceeded: 'Rafraîchissement réussi',
        noMoreData: 'Plus de données',
        pullingText: 'La liste peut être actualisée...',
        ReleaseText: 'Libérez pour rafraîchir...',
        loadingText: 'Chargé dans...',
        noData: 'Aucun enregistrement',
        xq: 'Détails',
        submit: 'Confirmation',
        kefu: 'Service à la clientèle en ligne'
    },
    ChangeType: {
        Upper: 'Recharge du système',
        Lower: 'Le retrait du système',
        Win: 'Bénéfice',
        Bet: 'Investissements',
        BuyMining: 'Excavation',
        CoinWin: 'Bénéfice de la nouvelle monnaie',
        SettleMining: 'Retour du principal de l\'exploitation minière',
        GiveInterest: 'Revenu de l\'exploitation minière',
        BuyCoin: 'La nouvelle monnaie n\'a pas été signée',
        BuyCoinBack: 'L\'abonnement SGD revient',
        UsdtToBtc: 'Usdt en Btc',
        UsdtToEth: 'Usdt en Eth',
        BtcToUsdt: 'Btc en Usdt',
        BtcToEth: 'Btc en Eth',
        EthToUsdt: 'Eth en Usdt',
        EthToBtc: 'Eth en Btc',
        CoinFrozen: 'Nouvelle monnaie à la loterie gelée',
        CoinThaw: 'Nouvelle monnaie dégelée',
        CoinCancel: 'Annulation de la commande de monnaie',
        CoinBuy: 'Acheter des pièces de monnaie',
        CoinBuyEntrust: 'Commande d\'achat de pièces de monnaie',
        CoinSellEntrust: 'Commande de vente de pièces',
        CoinSell: 'Pièces à vendre',
        ContractBuy: 'Contrat de commande, déduction de la marge',
        ContractCharge: 'Contrat de commande, frais de traitement déduits',
        ContractCancelToBond: 'Retrait du contrat et remboursement de la marge',
        ContractCancelToCharge: 'Frais de remboursement de l\'annulation du contrat',
        ContractCloseProfit: 'Revenu de liquidation de contrat'
    },
    trade: {
        title: 'Pièces de monnaie',
        Buy: 'Acheter',
        Sell: 'Vendre',
        pc: 'Liquidation',
        cb: 'Coût',
        xj1: 'Prix actuel',
        yk: 'Profits et pertes',
        xianjia: 'Limite de prix',
        jiage: 'Prix',
        shuliang: 'Quantité',
        keyong: 'Disponible',
        yonghuchicang: 'La position de l\'utilisateur',
        fxlts: 'Taux de risque = marge de dépôt/capitaux propres du client multiplié par 100%, où les capitaux propres du client sont égaux aux fonds disponibles du compte, marge de transaction initiale, profit et perte flottant',
        fxl: 'Taux de risque',
        dqwt: 'Confié en cours',
        all: 'Tous',
        name: 'Nom',
        shijian: 'Temps',
        zyjg: 'Trading au meilleur prix actuel',
        caozuo: 'Opération',
        xj: 'Limite de prix',
        cc: 'Entreposage',
        leixing: 'Type',
        long: 'Faire plus',
        short: 'Faire court',
        sj: 'Prix du marché',
        jiaoyie: 'Montant des transactions',
        orderNo: 'Numéro de commande',
        weituojia: 'Prix de commission',
        chengjiaojia: 'Prix de transaction',
        chengjiaoe: 'Chiffre d\'affaires',
        weituo: 'Méthode de commission',
        qbwt: 'Toutes les commissions',
        lswt: 'Commandes historiques',
        tips: 'Veuillez saisir le prix correct',
        tips1: 'Veuillez entrer la quantité',
        Create: 'Confié',
        Wait: 'Dans la transaction',
        Complete: 'A fermé',
        Cancel: 'La commande a été retirée',
        zsj: 'Prix stop loss',
        zyj: 'Stop-profit',
        cczsy: 'Revenu total de la position',
        ykbl: 'Proportion de profit et de perte',
        bili: 'Proportion',
        jyss: 'Nombre de mains commerciales',
        bzj: 'La marge',
        sxf: 'Frais de traitement',
        gg: 'Multiple de levier',
        yjpc: 'Un clic pour fermer la position',
        status: 'Statut',
    },
    auth: {
        title: 'Authentification d\'identité',
        title1: 'Authentification d\'identité',
        low: 'Certification primaire',
        high: 'Certification avancée',
        success: 'Certification réussie',
        tips: 'Veuillez d\'abord faire la certification primaire',
        tips1: 'Veuillez saisir le vrai nom',
        tips2: 'Veuillez saisir le numéro d\'identification',
        tips3: 'Veuillez télécharger la photo avant de votre carte d\'identité',
        tips4: 'Veuillez télécharger une photo au dos de votre carte d\'identité',
        tips5: 'Veuillez télécharger une photo de poche',
        qrz: 'Aller à la certification',
        shz: 'En cours de révision',
        rzsb: 'Échec de la certification',
        userName: 'Nom réel',
        IDNumber: 'Numéro d\'identification',
        zmz: 'Photo frontale de la carte d\'identité',
        bmz: 'Photo au dos de la carte d\'identité',
        scz: 'Photo à main'
    },
    share: {
        title: 'Mon code de promotion',
        cp: 'Copier l\'adresse',
        success: 'Réplication réussie'
    },
    property: {
        Contract: 'Pièces de monnaie',
        Exchange: 'Contrat de secondes',
        zzc: 'Actif total équivalent',
        mhyzh: 'Compte de contrat en secondes',
        bbzh: 'Comptes de pièces de monnaie',
        available: 'Disponible',
        OnOrders: 'Gel',
        Estimated: 'Plié',
        cw: 'Documents financiers',
        number: 'Quantité',
        record: 'Enregistrement',
        Transfer: 'Le transfert',
        time: 'Temps',
        dhsl: 'Quantité de change',
        tips: 'Veuillez saisir la quantité de change',
        qbdh: 'Tous les échanges',
        dhhl: 'Taux de change',
        ky: 'Disponible',
        kd: 'Disponible',
        sxfbl: 'Proportion des frais de traitement',
        sxf: 'Frais de traitement'
    },
    statusLang: {
        Review: 'Audit',
        Success: 'Succès',
        Fail: 'Échec'
    },
    with: {
        title: 'Retrait en espèces',
        history: 'Enregistrement de retrait',
        ldz: 'Adresse de la chaîne',
        tbdz: 'Adresse de la monnaie',
        tips: 'Veuillez saisir l\'adresse de la devise',
        tips1: 'Veuillez saisir la quantité de pièces',
        tips2: 'Veuillez saisir le mot de passe de paiement',
        tbsl: 'Quantité de pièces',
        ky: 'Disponible',
        zjmm: 'Mot de passe de paiement',
        sxf: 'Frais de traitement',
        dz: 'Nombre estimé de reçus'
    },
    tabBar: {
        home: 'Page d\'accueil',
        Quotation: 'Citations',
        Quotation2: 'Citations du marché',
        Trade: 'Pièces de monnaie',
        Contract: 'Contrat de secondes',
        assets: 'Actifs',
        qhyy: 'Changer de langue',
        zyjb: 'Stockage intelligent',
        jsjy: 'Transaction rapide',
        jryw: 'Opérations financières',
        hyjy: 'Transactions contractuelles',
        bbjy: 'Le commerce binance coin'
    },
    home: {
        UserName: 'Compte',
        kjmb: 'Acheter des pièces rapidement',
        score: 'Points de crédit',
        zc: 'Soutien BTC, USDT, etc',
        scwk: 'Stockage intelligent',
        sgxb: 'Abonnement à la nouvelle monnaie',
        aqkx: 'Sécurité et crédibilité',
        aqkx1: 'La principale plateforme de trading d\'actifs numériques au monde',
        zfb: 'Liste des augmentations',
        dfb: 'Liste de baisse',
        mc: 'Nom',
        hy: 'Contrat',
        zxj: 'Dernier prix',
        zdb: 'Des hauts et des bas',
        sfyz: 'Authentification d\'identité',
        skfs: 'Méthode de collecte',
        share: 'Mon lien de partage',
        kjcb: 'Charge rapide',
        kjtb: 'Rapide à la monnaie',
        qbdz: 'Adresse du portefeuille',
        bps: 'Livre blanc',
        xgmm: 'Modifier le mot de passe',
        xgzfmm: 'Modifier le mot de passe de paiement',
        gywm: 'À propos de nous',
        tcdl: 'Sortie'
    },
    quotation: {
        title: 'Citations',
        mc: 'Quantité',
        zxj: 'Dernier prix',
        zdb: 'Des hauts et des bas',
        liang: 'La quantité'
    },
    contract: {
        kcslfw: 'Portée du nombre de positions ouvertes:',
        gao: 'Haut',
        di: 'Faible',
        liang: '24H quantité',
        placeholder: 'Veuillez entrer la quantité',
        SuccessfulPurchase: 'Acheter avec succès',
        smallMin: 'Minutes',
        hour: 'Heures',
        dayMin: 'Jours',
        Trend: 'Le temps partagé',
        ccjl: 'Liste des positions',
        ddjl: 'Enregistrement des commandes',
        sl: 'Quantité',
        gmj: 'Prix d\'achat',
        dqj: 'Prix actuel',
        yjyk: 'Vent et perte attendus',
        djs: 'Compte à rebours',
        gbjg: 'Prix de transaction',
        sc: 'Frais de traitement',
        pal: 'Profits et pertes',
        mhy: 'Contrat de secondes',
        sj: 'Temps',
        yll: 'Taux de rentabilité',
        mz: 'Acheter',
        md: 'Acheter et tomber',
        kcsl: 'Nombre de positions ouvertes',
        kcsj: 'Heure d\'ouverture',
        zhye: 'Solde du compte',
        qc: 'Annulation'
    },
    editPassword: {
        title: 'Modifier le mot de passe',
        jmm: 'Ancien mot de passe',
        xmm: 'Nouveau mot de passe',
        qrmm: 'Confirmer le mot de passe',
        placeholder: 'Ancien mot de passe',
        placeholder1: 'Définir un nouveau mot de passe',
        placeholder2: 'Veuillez saisir à nouveau le nouveau mot de passe',
        qrxf: 'Confirmation'
    },
    withPass: {
        title: 'Mot de passe de retrait',
        jmm: 'Ancien mot de passe',
        xmm: 'Nouveau mot de passe',
        qrmm: 'Confirmer le mot de passe',
        placeholder: 'Ancien mot de passe',
        placeholder1: 'Définir un nouveau mot de passe',
        placeholder2: 'Veuillez saisir à nouveau le nouveau mot de passe',
        qrxf: 'Confirmation',
        tips: 'Veuillez saisir un ancien mot de passe d\'au moins 6 chiffres',
        tips1: 'Veuillez saisir un nouveau mot de passe d\'au moins 6 chiffres',
        tips2: 'Veuillez saisir le mot de passe de confirmation correct',
        tips3: 'Nouveaux mots de passe incohérents saisis deux fois',
        tips4: 'Définir un mot de passe avec succès',
        confirm: 'Confirmation'
    },
    newBe: {
        title: 'Abonnement à la nouvelle monnaie',
        title1: 'Mon abonnement',
        scqx: 'Période de stockage de verrouillage',
        day: 'Jours',
        sy: 'Le reste',
        sdsg: 'Enregistrement',
        sysl: 'Quantité restante',
        gmsl: 'Quantité d\'achat',
        dj: 'Prix unitaire',
        tips: 'Veuillez saisir la quantité d\'achat',
        gmzj: 'Achat du prix total',
        qbye: 'Solde de portefeuille',
        fmzj: 'Achat du prix total',
        gm: 'Acheter',
        sgsj: 'Temps d\'achat',
        Currency: 'Monnaie',
        Request: 'Nombre de demandes',
        Passing: 'Par la quantité',
        MarketTime: 'Temps de mise sur le marché',
        sqz: 'Dans l\'application'
    },
    register: {
        title: 'Inscription',
        lang: 'Langue',
        send: 'Envoyer',
        Resend: 'Re-envoyer',
        Account: 'Compte',
        Password: 'Mot de passe',
        ConfirmPassword: 'Confirmer le mot de passe',
        InvitationCode: 'Code d\'invitation',
        btn: 'Inscription',
        amount: 'Compte',
        mailbox: 'Boîtes aux lettres',
        haveAccount: 'Avez-vous déjà un compte?',
        Login: 'Se connecter',
        placeholder: 'Veuillez saisir la boîte aux lettres',
        placeholder0: 'Veuillez saisir le compte',
        placeholder1: 'Veuillez saisir le mot de passe',
        placeholder2: 'Veuillez saisir le mot de passe de confirmation',
        placeholder3: 'Veuillez saisir le code d\'invitation',
        placeholder4: 'Veuillez saisir le code de vérification de la boîte aux lettres',
        placeholder5: 'Veuillez saisir la bonne boîte aux lettres',
        success: 'Inscription réussie',
        message: 'Mot de passe au moins 6 chiffres',
        message1: 'Confirmer le mot de passe au moins 6 chiffres',
        message2: 'Deux fois le mot de passe ne correspond pas',
        message3: 'Veuillez lire et cocher l\'accord',
        agree: 'Je suis d\'accord',
        xy: 'Contrat utilisateur et politique de confidentialité',
        qr: 'Confirmation'
    },
    lockming: {
        title: 'Liste des entrepôts de verrouillage',
        wkb: 'Creuser un trésor minier',
        wkgjd: 'L\'exploitation minière est plus simple',
        qsljl: 'Facile à recevoir des récompenses',
        yjzsr: 'Revenu total estimé',
        ck: 'L\'année de référence au cours des 3 derniers jours',
        tips: 'Au moins',
        rlx: 'Intérêts journaux',
        day: 'Jours',
        scqx: 'Période de stockage de verrouillage',
        buy: 'Acheter',
        zysl: 'Nombre de promesses',
        qbye: 'Solde de portefeuille',
        finished: 'Terminé',
        doing: 'En cours...',
        sl: 'Quantité',
        shouyilv: 'Taux de rendement',
        ks: 'Heure de début',
        js: 'Heure de fin'
    },
    login: {
        title: 'Se connecter',
        lang: 'Langue',
        Account: 'Compte',
        Password: 'Mot de passe',
        btn: 'Se connecter',
        placeholder1: 'Veuillez saisir le mot de passe',
        placeholder: 'Veuillez saisir le compte',
        fail: 'Veuillez remplir le formulaire complet',
        ForgotPassword: 'Mot de passe oublié',
        NoAccount: 'Pas encore de compte?',
        Registration: 'Inscription',
        server: 'Service à la clientèle en ligne',
        jzmm: 'Mémoriser les mots de passe',
        success: 'Connexion réussie',
        Home: 'Page d\'accueil'
    },
    moneyAddr: {
        title: 'Adresse du portefeuille',
        Currency: 'Monnaie',
        Address: 'Adresse',
        placeholder: 'Veuillez sélectionner',
        DepositAddress: 'Adresse de chargement de pièces',
        WalletAddress: 'Adresse du portefeuille',
        Date: 'Temps',
        add: 'Ajouter une adresse de portefeuille',
        ChainAddress: 'Adresse de la chaîne',
        qrcode: 'Le code QR du portefeuille',
        submit: 'Confirmation',
        tips: 'Veuillez choisir le type de portefeuille',
        tips1: 'Veuillez sélectionner le type d\'adresse de chaîne',
        tips2: 'Veuillez saisir l\'adresse du portefeuille',
        tips3: 'Veuillez télécharger le code QR du portefeuille',
        success: 'Succès'
    },
    recharge: {
        title: 'Recharge',
        title1: 'Détails de la recharge',
        title2: 'Détails de la présentation',
        ls: 'Détails de la recharge',
        td: 'Veuillez sélectionner les canaux de recharge suivants',
        bc: 'Enregistrer le code QR',
        address: 'Adresse de chargement de pièces',
        copy: 'Copier l\'adresse',
        num: 'Quantité de pièces',
        Upload: 'Télécharger des captures d\'écran des détails de paiement',
        Submit: 'Confirmation',
        tip: 'Veuillez entrer la quantité',
        tip1: 'S\'il vous plaît télécharger une capture d\'écran',
        copySuc: 'Réplication réussie'
    },
    newChange: {
        num: 'Veuillez entrer la quantité',
        psd: 'Veuillez saisir le mot de passe',
        hq: 'Citations',
        bb: 'Centre d\'aide',
        sec: 'Certification de nom réel',
        ass: 'Actifs',
        pool: 'Gestion financière',
        newc: 'À propos de nous',
        rechange: 'Recharge',
        with: 'Service à la clientèle',
        help: 'Centre d\'aide',
        a1: 'Finances',
        a2: 'Fonds en détention (USDT)',
        a3: 'Bénéfice d\'aujourd\'hui',
        a4: 'Revenu cumulé',
        a5: 'En cours',
        a6: 'Commandes',
        a7: 'Règles',
        a8: 'Retour quotidien',
        a9: 'Limite unique',
        a10: 'Boucles',
        a11: 'Pecently (revenu quotidien)',
        a12: 'Limites individuelles',
        a13: 'Nombre d\'hébergement:',
        a14: 'Abonnement',
        a15: 'Calcul des gains',
        a16: 'Le déménagement des briques passe par l\'hébergement USDT à la plate-forme, et la plate-forme dispose d\'une équipe professionnelle pour effectuer l\'arbitrage de déplacement des briques...',
        a17: 'Un membre a acheté 10000U sur la plate-forme et a choisi un produit de gestion de patrimoine avec une durée de 5 jours et un taux de rendement quotidien de 0,3% à 0,5%. Le plus haut quotidien: 10000U x 0,5% = 50 U n, c\'est-à-dire qu\'après 5 jours, vous pouvez obtenir un revenu de 150U-250U, le revenu est payé quotidiennement et le revenu émis peut être pris à tout moment. Le principal déposé a expiré et sera après...',
        a18: 'À propos des dommages-intérêts',
        a19: 'Si vous souhaitez transférer le principal non expiré, des dommages-intérêts seront générés. Dommages-intérêts = ratio de liquidation par défaut * jours restants * nombre d\'investissements. Exemple N: Le ratio de règlement par défaut du produit est de 0,4%, les 3 jours restants sont dus, le montant de l\'investissement est de 1000, et les dommages-intérêts sont = 0,4% * 3...',
        a20: 'Je veux rejoindre'
    },
    msgbank: {
        msgTitle: 'message',
        noticeTitle: 'annonce',
        msgContent: 'Vous avez un message non lu',
        msgBtn: 'aller voir',
        bankCard: 'carte bancaire',
        inputTips: 'veuillez entrer les informations complètes',
        submitSuccess: 'soumission réussie',
        CardUserName: 'nom de la personne qui ouvre le compte',
        BankName: 'banque dépositaire',
        OpenBank: 'ouvrir un compte',
        CardNo: 'numéro de carte bancaire',
        noBindBankmsg: 'vous n·avez pas encore lié votre carte bancaire',
        noBindBankBtn: 'débrancher',
        modify: 'Modifier',
    }
}