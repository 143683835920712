module.exports = {
    pcregister: {
        p1: '已有賬號?',
        dl: '登錄',
        zc: '註冊',
        p4: '賬戶',
        p5: '請輸入賬號',
        p6: '請輸入密碼',
        p7: '請輸入確認密碼',
        p8: '請輸入邀請碼',
        p9: '我同意',
        p10: '《用戶協議和隱私政策》',
        p11: '郵箱',
        p12: '請輸入郵箱',
        p13: '請輸入郵箱驗證碼',
        p14: '發送',
        p15: '年',
        p16: '月',
        p17: '天',
        p18: '小時',
        p19: '分鐘',
        p20: '秒'
    },
    pclogin: {
        l1: '沒有賬號?',
        l2: '註冊',
        l3: '登錄',
        l4: '請輸入用戶名',
        l5: '請輸入密碼',
        l6: '忘記密碼',
        l7: '登錄'
    },
    pccontract: {
        ss: '市價',
        xj: '限價',
        gg: '槓桿'
    },
    pcrapidly: {
        zf: '漲幅',
        dd: '訂單',
        bdsj: '本地時間',
        ky: '可用'
    },
    pchome: {
        global1: '全球領先的數字資產交易平台',
        global2: '它為100多個國家的數百萬用戶提供安全、可信的數字資產交易和資產管理服務。 ',
        global3: '安全可靠',
        global4: '五年數字資產金融服務經驗。專注於分佈式架構和抗DDoS攻擊系統。 ',
        global5: '全球生態佈局',
        global6: '已在多個國家設立本地化交易服務中心，打造融合多種業態的區塊鏈生態系統。 ',
        global7: '用戶至上',
        global8: '建立先行補償機制，設立投資者保護基金。 ',
        whever1: '隨時隨地多平台終端交易',
        whever2: '覆蓋iOS、Android、Windows多個平台，支持全業務功能。 ',
        register1: '註冊一個',
        register3: '全球賬戶開始交易',
        register2: '立即註冊'
    },
    pcbottom: {
        box1: '全球領先',
        box2: '最好的團隊之一',
        box3: '更多',
        box4: '關於我們',
        box5: '白皮書',
        box6: '服務',
        box7: '下載',
        box8: '常見問題',
        box9: '在線客服',
        box10: '規定',
        box11: '用戶協議',
        box12: '隱私政策',
        box13: '法律聲明',
        box14: '聯繫'
    },
    pcAbout_us: {
        t1: '歷程',
        t2: '我們，7年之路',
        t3: '從2015年誕生以來，我們就心無旁騖的埋頭為區塊鏈服務，專職技術、通道搭建服務。 ',
        t4: '2014-2017年經歷了“穩中有升”的上行期，2017-2018上半年是“買錢致富”的瘋狂期，從2019年下半年到今天的寒冬期，我練就了一身真本事。無數成功的伙伴，讓低調的我們在業界擁有高調的聲譽。 2022年，我們將擁有全新的平台V3.0，並成立新的股份制公司BitGoldEX，在全球100多個國家開展業務。 ',
        t5: '我們在找誰',
        t6: '從成立到今天',
        t7: '一直在尋找有困難，並與自己氣味相投的服務商，幾年時間成功地為幾十個服務商解決問題. ',
        t8: '在理性的今天，如果你希望找一個好幫手，能夠攜手實現自己繼續前進的伙伴——或許，可以找我們聊聊。 ',
        t9: '請相信：你在找的人，其實也一直在找你。 ',
        t10: '服務',
        t11: '沒有業務員，一樣有業務',
        t12: '朋友為你介紹生意，說明你為人還行。同行為你介紹生意，說明你口碑還不錯。當客戶為你介紹生意，那證明你是值得信賴的。 ',
        t13: '精神',
        t14: '我們是塊磚，哪有需要往哪端',
        t15: '不同的客戶，要求不一樣。不同的產品，特性不一樣。不同的時機，目標不一樣。我們存在的意義，就是去適應不同服務商的理念、制度和要求，解決各種各樣的問題。 ',
        t16: '我們願意做一塊有思想、有作用的磚，哪裡有需要，我們就往哪端。 '
    },
    pcproblem: {
        p1: '常見問題',
        p2: '為什麼轉換後的資產數量會發生變化？ ',
        p3: '資產中的等值計算是當前持有的數字貨幣換算成USDT的價值，因數字資產的價格波動而變化。您的數字資產數量沒有改變。 ',
        p4: '平台交易忘記資金密碼處理方法',
        p5: '如忘記平台交易資金密碼，請前往“我的-設置-點擊設置資金密碼”或聯繫客服重置',
        p6: '交易購買後資金流向哪裡？ ',
        p7: '採購訂單完成後，資金將進入資產中的法幣賬戶。如需購買資產進行合約交易或期貨交易，請到資金劃轉模塊進行劃轉。 ',
        p8: '為什麼要限制暱稱的更改次數',
        p9: '出於您交易的安全考慮，我們目前限制暱稱只能修改一次。 ',
        p10: '轉移的時候是什麼意思？為什麼需要轉移',
        p11: '為保證您賬戶之間資金的獨立性，便於您合理控制風險，我們特此劃分各主要交易模塊的賬戶。轉賬是指交易賬戶之間進行資產轉換的過程。 ',
        p12: '用戶合約的賬戶權益如何計算？ ',
        p13: '合約賬戶淨值=頭寸保證金+頭寸浮動盈虧+活期賬戶可用金額',
        p14: '開倉時止損和止盈設置的值是多少？應該怎麼設置？ ',
        p15: '以盈虧為您設定的盈虧上限。當達到設定的止盈止損金額時，系統會自動平倉。可用於購買合約時的風險控制。設置止盈量的一半為：增加量x數量x槓桿倍數，設置止損。我們建議您根據自己的實際資產情況進行設置，合理控制風險。 ',
        p16: '如何降低合同風險？ ',
        p17: '您可以通過劃轉資金的方式將剩餘賬戶的可用資產轉入合約賬戶，或者通過減少持倉來降低風險',
        p18: '合約開倉的數量是什麼意思？ ',
        p19: '未平倉頭寸的數量代表您期望購買的貨幣數量。例如：在BTC/USDT交易對的開盤頁面選擇，做多，輸入價格為1000USDT，輸入數量為10BTC，即：您預計買入10個BTC，單價為1000USDT。 ',
        p20: '合同中的手續費是如何計算的？ ',
        p21: '手續費=開倉價格*開倉數量*手續費率',
        p22: '強制平倉注意事項',
        p23: '風險程度是衡量您資產風險的指標。當風險度等於100%時，您的倉位被視為平倉，風險度=（持有保證金/合約賬戶權益）*100%，為防止用戶穿倉，系統設置調整風險度比。當風險程度達到系統設定的風險值時，系統將強制平倉。例如：系統設置的調整比例為10%，那麼當您的風險度大於等於90%時，您的所有倉位將被系統強制平倉。 ',
        p24: '合約交易規則是什麼？ ',
        p25: '交易類型',
        p26: '交易類型分為多頭（買入）和空頭（賣出）兩個方向：',
        p27: '買入多頭（看漲）表示您認為當前指數有可能上漲，並且您想買入一定數量的一定數量的以您設定的價格或當前市場價格簽訂合約。 ',
        p28: '賣空（看跌）是指您認為當前指數可能下跌，並希望以您設定的價格或當前市場價格賣出一定數量的新合約。 ',
        p29: '下單方式',
        p30: '限價下單：您需要指定下單的價格和數量',
        p31: '市價單：您只需設置下單數量，價格為當前市價',
        p32: '持倉',
        p33: '當您提交的開倉指令完成後，稱為持倉',
        p34: '合約交割問題',
        p35: '平台合約為永續合約，不設交貨時間。只要係統不滿足強平條件或者您沒有手動平倉，您就可以永久持倉。系統平倉',
        p36: '1：達到止盈止損設定值，系統自動平倉',
        p37: '2：風險過高，系統強制平倉',
        p38: '合約交易的保證金是多少？ ',
        p39: '在合約交易中，用戶可以根據合約價格和數量，以及對應的槓桿倍數參與合約的買賣。用戶開倉時會佔用保證金。開倉保證金越多，賬戶風險就越高。 ',
        p40: '什麼是限價單和市價單？ ',
        p41: '限價單是指您期望以什麼價格委託平台進行交易，市價單是指直接委託平台以當前價格進行交易。在開倉規則中，市價單優先於限價單。如果您選擇限價單，請按照幣種當前市場價格合理開倉，避免因開倉價格不合理造成損失。 ',
        p42: '合約交易的風險等級代表什麼？ ',
        p43: '風險度是您合約賬戶中的風險指標。風險程度等於 100% 被視為清算。我們建議當您的風險超過50%時，您需要謹慎開倉，避免因平倉而造成損失。您可以通過補充合約資產的可用資金或減倉來降低風險。 ',
        p44: '為什麼需要貨幣兌換？ ',
        p45: '交易所的目的是讓您資產中不同幣種的資金順利流通，期貨賬戶中獲得的QCC可以自由兌換成USDT進行交易。其他賬戶中的USDT也可以自由兌換成QCC進行交易。 ',
        p46: '為什麼轉賬後對方沒有及時收到轉賬？ ',
        p47: '首先，請確保您在轉賬時填寫的支付方式與訂單詳情頁面顯示的支付方式完全相同。然後請確認您的付款方式是立即付款還是延遲付款。最後，請聯繫您的銀行和支付機構進行系統維護或其他原因。 ',
        p48: '如何通過平台購買USDT？ ',
        p49: '方法一：通過平台買賣清單選擇您要買入的訂單進行買賣。 ',
        p50: '方法二：點擊發布按鈕，根據您的需要發布買單或賣單進行快速交易。該系統將快速為您匹配買賣用戶。 ',
        p51: '注意：如果採購訂單15分鐘後仍未付款，系統會自動取消訂單，請及時付款。當天取消訂單超過3次，當天不能再次交易，次日恢復交易權限。 '
    },
    public: {
        zxkf: '在線客服',
        sure: '確認',
        cancel: '取消',
        RefreshSucceeded: '刷新成功',
        noMoreData: '沒有更多數據了',
        pullingText: '下拉即可刷新 ...',
        ReleaseText: '釋放即可刷新 ...',
        loadingText: '加載中 ...',
        noData: '沒有記錄',
        xq: '詳情',
        submit: '確認',
        kefu: '在線客服'
    },
    ChangeType: {
        Upper: '系統充值',
        Lower: '系統提現',
        Win: '盈利',
        Bet: '投資',
        BuyMining: '挖礦',
        CoinWin: '新幣盈利',
        SettleMining: '挖礦本金返回',
        GiveInterest: '挖礦收益',
        BuyCoin: '新幣未中籤',
        BuyCoinBack: '新幣申購返回',
        UsdtToBtc: 'Usdt轉Btc',
        UsdtToEth: 'Usdt轉Eth',
        BtcToUsdt: 'Btc轉Usdt',
        BtcToEth: 'Btc轉Eth',
        EthToUsdt: 'Eth轉Usdt',
        EthToBtc: 'Eth轉Btc',
        CoinFrozen: '新幣中籤凍結',
        CoinThaw: '新幣解凍',
        CoinCancel: '幣幣訂單撤銷',
        CoinBuy: '幣幣買入',
        CoinBuyEntrust: '幣幣買入委託',
        CoinSellEntrust: '幣幣賣出委託',
        CoinSell: '幣幣賣出',
        ContractBuy: '合約下單，扣除保證金',
        ContractCharge: '合約下單，扣除手續費',
        ContractCancelToBond: '合約撤銷退還保證金',
        ContractCancelToCharge: '合約撤銷退還手續費',
        ContractCloseProfit: '合約平倉收益'
    },
    trade: {
        title: '幣幣',
        Buy: '買入',
        Sell: '賣出',
        pc: '平倉',
        cb: '成本',
        xj1: '現價',
        yk: '盈虧',
        xianjia: '限價',
        jiage: '價格',
        shuliang: '數量',
        keyong: '可用',
        yonghuchicang: '用戶持倉',
        fxlts: '風險率=持倉保證金/客戶權益再乘以100%，其中客戶權益等於賬戶可用資金+初始交易保證金+浮動盈虧',
        fxl: '風險率',
        dqwt: '當前委託',
        all: '全部',
        name: '名稱',
        shijian: '時間',
        zyjg: '以當前最優價格交易',
        caozuo: '操作',
        xj: '限價',
        cc: '持倉',
        leixing: '類型',
        long: '做多',
        short: '做空',
        sj: '市價',
        jiaoyie: '交易額',
        orderNo: '訂單號',
        weituojia: '委託價',
        chengjiaojia: '成交價',
        chengjiaoe: '成交額',
        weituo: '委託方式',
        qbwt: '全部委託',
        lswt: '歷史委託',
        tips: '請輸入正確的價格',
        tips1: '請輸入數量',
        Create: '委託中',
        Wait: '交易中',
        Complete: '已平倉',
        Cancel: '已撤單',
        zsj: '止損價',
        zyj: '止盈價',
        cczsy: '持倉總收益',
        ykbl: '盈虧比例',
        bili: '比例',
        jyss: '交易手數',
        bzj: '保證金',
        sxf: '手續費',
        gg: '槓桿倍數',
        yjpc: '一鍵平倉',
        status: '狀態',
    },
    auth: {
        title: '身份認證',
        title1: '身份認證',
        low: '初級認證',
        high: '高級認證',
        success: '認證成功',
        tips: '請先進行初級認證',
        tips1: '請輸入真實姓名',
        tips2: '請輸入身份證號碼',
        tips3: '請上傳身份證正面照片',
        tips4: '請上傳身份證背面照片',
        tips5: '請上傳手持照片',
        qrz: '去認證',
        shz: '審核中',
        rzsb: '認證失敗',
        userName: '真實姓名',
        IDNumber: '身份證號',
        zmz: '身份證正面照片',
        bmz: '身份證背面照片',
        scz: '手持照'
    },
    share: {
        title: '我的推廣碼',
        cp: '複製地址',
        success: '複製成功'
    },
    property: {
        Contract: '幣幣',
        Exchange: '秒合約',
        zzc: '總資產折合',
        mhyzh: '秒合約賬戶',
        bbzh: '幣幣賬戶',
        available: '可用',
        OnOrders: '凍結',
        Estimated: '折合',
        cw: '財務記錄',
        number: '數量',
        record: '記錄',
        Transfer: '劃轉',
        time: '時間',
        dhsl: '兌換數量',
        tips: '請輸入兌換數量',
        qbdh: '全部兌換',
        dhhl: '兌換匯率',
        ky: '可用',
        kd: '可得',
        sxfbl: '手續費比例',
        sxf: '手續費'
    },
    statusLang: {
        Review: '審核',
        Success: '成功',
        Fail: '失敗'
    },
    with: {
        title: '提現',
        history: '提現記錄',
        ldz: '鏈地址',
        tbdz: '提現地址',
        tips: '請輸入提現地址',
        tips1: '請輸入提現數量',
        tips2: '請輸入支付密碼',
        tbsl: '提現數量',
        ky: '可用',
        zjmm: '支付密碼',
        sxf: '手續費',
        dz: '預計到賬數量'
    },
    tabBar: {
        home: '首頁',
        Quotation: '行情',
        Quotation2: '市場行情',
        Trade: '幣幣',
        Contract: '秒合約',
        assets: '資產',
        qhyy: '切換語言',
        zyjb: '智能存儲',
        jsjy: '極速交易',
        jryw: '金融業務',
        hyjy: '合約交易',
        bbjy: '幣幣交易'
    },
    home: {
        UserName: '賬戶',
        kjmb: '快捷買幣',
        score: '信用分',
        zc: '支持BTC、USDT等',
        scwk: '智能存儲',
        sgxb: '新幣申購',
        aqkx: '安全可信',
        aqkx1: '全球領先的數字資產交易平台',
        zfb: '漲幅榜',
        dfb: '跌幅榜',
        mc: '名稱',
        hy: '合約',
        zxj: '最新價',
        zdb: '漲跌幅',
        sfyz: '身份認證',
        skfs: '收款方式',
        share: '我的分享鏈接',
        kjcb: '快捷充值',
        kjtb: '快捷提現',
        qbdz: '錢包地址',
        bps: '白皮書',
        xgmm: '修改密碼',
        xgzfmm: '修改支付密碼',
        gywm: '關於我們',
        tcdl: '退出'
    },
    quotation: {
        title: '行情',
        mc: '數量',
        zxj: '最新價',
        zdb: '漲跌幅',
        liang: '量'
    },
    contract: {
        kcslfw: '開倉數量範圍：',
        gao: '高',
        di: '低',
        liang: '24H量',
        placeholder: '請輸入數量',
        SuccessfulPurchase: '購買成功',
        smallMin: '分鐘',
        hour: '小時',
        dayMin: '天',
        Trend: '分時',
        ccjl: '持倉列表',
        ddjl: '訂單記錄',
        sl: '數量',
        gmj: '購買價',
        dqj: '當前價',
        yjyk: '預計盈虧',
        djs: '倒計時',
        gbjg: '成交價',
        sc: '手續費',
        pal: '盈虧',
        mhy: '秒合約',
        sj: '時間',
        yll: '盈利率',
        mz: '買漲',
        md: '買跌',
        kcsl: '開倉數量',
        kcsj: '開倉時間',
        zhye: '賬戶餘額',
        qc: '取消'
    },
    editPassword: {
        title: '修改密碼',
        jmm: '舊密碼',
        xmm: '新密碼',
        qrmm: '確認密碼',
        placeholder: '舊密碼',
        placeholder1: '設置新密碼',
        placeholder2: '請重新輸入新密碼',
        qrxf: '確認'
    },
    withPass: {
        title: '取款密碼',
        jmm: '舊密碼',
        xmm: '新密碼',
        qrmm: '確認密碼',
        placeholder: '舊密碼',
        placeholder1: '設置新密碼',
        placeholder2: '請重新輸入新密碼',
        qrxf: '確認',
        tips: '請輸入至少6位的舊密碼',
        tips1: '請輸入至少6位的新密碼',
        tips2: '請輸入正確的確認密碼',
        tips3: '兩次輸入的新密碼不一致',
        tips4: '設置密碼成功',
        confirm: '確認'
    },
    newBe: {
        title: '新幣申購',
        title1: '我的申購',
        scqx: '鎖倉期限',
        day: '天',
        sy: '剩餘',
        sdsg: '記錄',
        sysl: '剩餘數量',
        gmsl: '購買數量',
        dj: '單價',
        tips: '請輸入購買數量',
        gmzj: '購買總價',
        qbye: '錢包餘額',
        fmzj: '購買總價',
        gm: '購買',
        sgsj: '申購時間',
        Currency: '幣種',
        Request: '申請數量',
        Passing: '通過數量',
        MarketTime: '上市時間',
        sqz: '申請中'
    },
    register: {
        title: '註冊',
        lang: '語言',
        send: '發送',
        Resend: '重新發送',
        Account: '賬戶',
        Password: '密碼',
        ConfirmPassword: '確認密碼',
        InvitationCode: '邀請碼',
        btn: '註冊',
        amount: '賬號',
        mailbox: '郵箱',
        haveAccount: '是否已有賬號？ ',
        Login: '登錄',
        placeholder: '請輸入郵箱',
        placeholder0: '請輸入賬號',
        placeholder1: '請輸入密碼',
        placeholder2: '請輸入確認密碼',
        placeholder3: '請輸入邀請碼',
        placeholder4: '請輸入郵箱驗證碼',
        placeholder5: '請輸入正確的郵箱',
        success: '註冊成功',
        message: '密碼至少6位',
        message1: '確認密碼至少6位',
        message2: '兩次密碼不匹配',
        message3: '請閱讀並勾選協議',
        agree: '我同意',
        xy: '用戶協議和隱私政策',
        qr: '確認'
    },
    lockming: {
        title: '鎖倉列表',
        wkb: '挖礦寶',
        wkgjd: '挖礦更簡單',
        qsljl: '輕鬆領獎勵',
        yjzsr: '預計總收益',
        ck: '近3日參考年化',
        tips: '至少 ',
        rlx: '日利息',
        day: '天',
        scqx: '鎖倉期限',
        buy: '購買',
        zysl: '質押數量',
        qbye: '錢包餘額',
        finished: '完成',
        doing: '進行中...',
        sl: '數量',
        shouyilv: '收益率',
        ks: '開始時間',
        js: '結束時間'
    },
    login: {
        title: '登錄',
        lang: '語言',
        Account: '賬戶',
        Password: '密碼',
        btn: '登錄',
        placeholder1: '請輸入密碼',
        placeholder: '請輸入賬號',
        fail: '請填寫完整的表格',
        ForgotPassword: '忘記密碼',
        NoAccount: '還沒有賬號？ ',
        Registration: '註冊',
        server: '在線客服',
        jzmm: '記住密碼',
        success: '登錄成功',
        Home: '首頁'
    },
    moneyAddr: {
        title: '錢包地址',
        Currency: '幣種',
        Address: '地址',
        placeholder: '請選擇',
        DepositAddress: '充值地址',
        WalletAddress: '錢包地址',
        Date: '時間',
        add: '添加錢包地址',
        ChainAddress: '鏈地址',
        qrcode: '錢包二維碼',
        submit: '確認',
        tips: '請選擇錢包類型',
        tips1: '請選擇鏈地址類型',
        tips2: '請輸入錢包地址',
        tips3: '請上傳錢包二維碼',
        success: '成功'
    },
    recharge: {
        title: '充值',
        title1: '充值明細',
        title2: '提現明細',
        ls: '充值明細',
        td: '請選擇以下充值通道',
        bc: '保存二維碼',
        address: '充值地址',
        copy: '複製地址',
        num: '充值數量',
        Upload: '上傳支付詳情截圖',
        Submit: '確認',
        tip: '請輸入數量',
        tip1: '請上傳截圖',
        copySuc: '複製成功'
    },
    newChange: {
        num: '請輸入數量',
        psd: '請輸入密碼',
        hq: '行情',
        bb: '幫助中心',
        sec: '實名認證',
        ass: '資產',
        pool: '理財',
        newc: '關於我們',
        rechange: '充值',
        with: '客服',
        a1: '財務',
        a2: '在押資金(USDT)',
        a3: '今日利潤',
        a4: '累計收益',
        a5: '進行中',
        a6: '訂單',
        a7: '規則',
        a8: '每日回報',
        a9: '單次限制',
        a10: '循環',
        a11: 'Pecently(每日收入)',
        a12: '單個限制',
        a13: '託管數量：',
        a14: '訂閱',
        a15: '收益計算',
        a16: '搬磚是通過託管USDT到平台，平台有專業團隊進行搬磚套利...',
        a17: '某會員在平台入金10000U，選擇了一款期限為5天，日收益率為0.3%至0.5%的理財產品。每日最高：10000U x 0.5% = 50 U n 即 5天后可獲得150U-250U的收益，收益每日發放，發放的收益可隨時取用。 存入的本金已過期，之後將是...',
        a18: '關於違約金',
        a19: '如果您要轉讓未到期本金，將產生違約金。違約金=違約清算比例*剩余天數*投資次數。 N例：產品默認結算比例為0.4%，剩餘3天到期，投資金額為1000，則違約金=0.4%*3...',
        a20: '我要加入',
        help: '幫助中心',
    },
    msgbank: {
        msgTitle: '消息',
        noticeTitle: '公告',
        msgContent: '您有未讀的消息',
        msgBtn: '去查看',
        bankCard: '銀行卡',
        inputTips: '請輸入完整資訊',
        submitSuccess: '提交成功',
        CardUserName: '開戶人姓名',
        BankName: '開戶銀行',
        OpenBank: '開戶網點',
        CardNo: '銀行卡號',
        noBindBankmsg: '您還沒有綁定銀行卡',
        noBindBankBtn: '去綁定',
        modify: '修改',
    }
}