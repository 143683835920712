module.exports = {
    pcregister: {
        p1: 'لديك حساب ؟',
        dl: 'تسجيل الدخول',
        zc: 'التسجيل',
        p4: 'الحسابات',
        p5: 'الرجاء إدخال الحساب',
        p6: 'الرجاء إدخال كلمة السر',
        p7: 'الرجاء إدخال كلمة مرور للتأكيد',
        p8: 'الرجاء إدخال رمز الدعوة',
        p9: 'أنا موافق',
        p10: 'اتفاقية المستخدم وسياسة الخصوصية',
        p11: 'صندوق البريد',
        p12: 'الرجاء إدخال البريد الإلكتروني',
        p13: 'الرجاء إدخال رمز التحقق في البريد الإلكتروني',
        p14: 'إرسال',
        p15: 'سنوات',
        p16: 'الشهر',
        p17: 'يا إلهي',
        p18: 'ساعات',
        p19: 'دقيقة',
        p20: 'ثواني'
    },
    pclogin: {
        l1: 'لا يوجد حساب ؟',
        l2: 'التسجيل',
        l3: 'تسجيل الدخول',
        l4: 'الرجاء إدخال اسم المستخدم',
        l5: 'الرجاء إدخال كلمة السر',
        l6: 'نسيت كلمة المرور',
        l7: 'تسجيل الدخول'
    },
    pccontract: {
        ss: 'سعر السوق',
        xj: 'حد السعر',
        gg: 'الرافعة المالية'
    },
    pcrapidly: {
        zf: 'الزيادة',
        dd: 'أوامر',
        bdsj: 'التوقيت المحلي',
        ky: 'متاح'
    },
    pchome: {
        global1: 'منصة تداول الأصول الرقمية الرائدة في العالم',
        global2: 'يوفر خدمات آمنة وموثوقة لمعاملات الأصول الرقمية وإدارة الأصول لملايين المستخدمين في أكثر من 100 دولة.',
        global3: 'آمنة وموثوق بها',
        global4: 'خمس سنوات من الخبرة في الخدمات المالية للأصول الرقمية. التركيز على الهندسة المعمارية الموزعة وأنظمة مكافحة هجوم DDoS.',
        global5: 'التخطيط البيئي العالمي',
        global6: 'تم إنشاء مراكز خدمات المعاملات المحلية في العديد من البلدان لإنشاء نظام بيئي blockchain يدمج أشكال الأعمال المتعددة.',
        global7: 'المستخدم أولا',
        global8: 'إنشاء آلية تعويض متقدمة وإنشاء صندوق حماية المستثمر.',
        whever1: 'معاملات طرفية متعددة المنصات في أي وقت وفي أي مكان',
        whever2: 'يغطي أنظمة التشغيل iOS و Android و Windows ، ويدعم وظائف خدمة كاملة.',
        register1: 'تسجيل واحد',
        register3: 'بدء التداول في جميع أنحاء العالم',
        register2: 'التسجيل على الفور'
    },
    pcbottom: {
        box1: 'الرائدة في العالم',
        box2: 'واحدة من أفضل الفرق',
        box3: 'المزيد',
        box4: 'عن نحن',
        box5: 'ورقة بيضاء',
        box6: 'الخدمات',
        box7: 'تحميل',
        box8: 'الأسئلة الشائعة',
        box9: 'خدمة العملاء عبر الإنترنت',
        box10: 'اللوائح',
        box11: 'اتفاقية المستخدم',
        box12: 'سياسة الخصوصية',
        box13: 'بيان قانوني',
        box14: 'الاتصال'
    },
    pcAbout_us: {
        t1: 'الدورة',
        t2: 'نحن ، طريق 7 سنوات',
        t3: 'منذ ولدنا في عام 2015 ، كنا نخدم blockchain بلا مبالاة ، مع التكنولوجيا بدوام كامل وخدمات بناء القنوات.',
        t4: 'شهدت 2014-2017 فترة صعودية من "الاستقرار والارتفاع". كان النصف الأول من 2017-2018 فترة مجنونة من "شراء المال لتحقيق الثراء". من النصف الثاني من عام 2019 إلى الشتاء البارد اليوم ، مارست مهارتي الحقيقية. يتيح لنا عدد لا يحصى من الشركاء الناجحين أن يكون لدينا سمعة رفيعة المستوى في الصناعة. في عام 2022 ، سيكون لدينا منصة جديدة ، V3.0 ، وإنشاء شركة مساهمة جديدة ، BitGoldEX ، التي تعمل في أكثر من 100 دولة حول العالم.',
        t5: 'من الذي نبحث عنه ؟',
        t6: 'من التأسيس إلى اليوم',
        t7: 'كان يبحث عن مقدمي الخدمات الذين يواجهون صعوبات ويتشابهنا مع أنفسهم, لعدة سنوات في حل المشاكل بنجاح لعشرات من مقدمي الخدمات.',
        t8: 'في العقلانية اليوم ، إذا كنت ترغب في العثور على مساعد جيد ، يمكنك العمل معًا لتحقيق شريك في المضي قدمًا-ربما ، يمكنك التحدث إلينا.',
        t9: 'يرجى تصديق: الشخص الذي تبحث عنه كان يبحث عنك.',
        t10: 'الخدمات',
        t11: 'لا يوجد مندوب مبيعات ، نفس العمل',
        t12: 'يقدم لك الأصدقاء أعمالك ، مما يعني أنك بخير. يقدم لك الزملاء أعمالك ، مما يدل على أن سمعتك جيدة. عندما يقدم العملاء أعمالك ، فهذا يثبت أنك جدير بالثقة.',
        t13: 'روح',
        t14: 'نحن لبنة ، كيف نحتاجها ؟',
        t15: 'العملاء المختلفون لديهم متطلبات مختلفة. المنتجات المختلفة لها خصائص مختلفة. توقيت مختلف ، أهداف مختلفة. معنى وجودنا هو التكيف مع مفاهيم وأنظمة ومتطلبات مزودي الخدمة المختلفين ، وحل المشكلات المختلفة.',
        t16: 'نحن على استعداد لصنع لبنة ذات تفكير ودور ، أينما نحتاج إليها.'
    },
    pcproblem: {
        p1: 'الأسئلة الشائعة',
        p2: 'لماذا يتغير عدد الأصول بعد التحويل ؟',
        p3: 'الحساب المكافئ في الأصول هو قيمة العملة الرقمية المحتفظ بها حاليًا والتي يتم تحويلها إلى USDT ، والتي تتغير بسبب تقلبات أسعار الأصول الرقمية. لم يتغير عدد الأصول الرقمية الخاصة بك.',
        p4: 'تداول المنصة ينسى طريقة معالجة كلمة مرور الأموال',
        p5: 'إذا نسيت كلمة مرور أموال تداول النظام الأساسي ، فيرجى الانتقال إلى "My-الإعدادات-انقر فوق تعيين كلمة مرور الأموال" أو الاتصال بخدمة العملاء لإعادة تعيينها',
        p6: 'أين تتدفق الأموال بعد شراء الصفقة ؟',
        p7: 'بعد اكتمال أمر الشراء ، ستدخل الأموال في حساب العملة القانونية في الأصل. إذا كنت بحاجة إلى شراء أصول لتداول العقود أو العقود الآجلة ، فيرجى الانتقال إلى وحدة تحويل الأموال للتحول.',
        p8: 'لماذا الحد من عدد التغييرات في اللقب',
        p9: 'لأسباب تتعلق بالسلامة في معاملاتك ، نحد حاليًا من تعديل اللقب مرة واحدة فقط.',
        p10: 'ماذا يعني عند النقل ؟ لماذا تحتاج إلى نقل',
        p11: 'لضمان استقلالية الأموال بين حساباتك ، حتى تتمكن من التحكم بشكل معقول في المخاطر ، نقوم بتقسيم حسابات وحدات التداول الرئيسية. يشير التحويل إلى عملية تحويل الأصول بين حسابات التداول.',
        p12: 'كيف تحسب حقوق حساب عقد المستخدم ؟',
        p13: 'صافي قيمة حساب العقد = مبلغ الربح والخسارة المتاح في الحساب الجاري',
        p14: 'ما هي قيمة ضبط وقف الخسارة وجني الأرباح عند فتح المركز ؟ كيف ينبغي تعيين ؟',
        p15: 'استخدم الحد الأعلى للأرباح والخسائر الذي حددته لك. عند الوصول إلى المبلغ المحدد لإيقاف الربح ، سيقوم النظام تلقائيًا بإغلاق المركز. يمكن استخدامها للسيطرة على المخاطر عند شراء العقود. اضبط نصف كمية خفض الأرباح على النحو التالي: زيادة الكمية x عدد الرافعة المتعددة ، وضبط وقف الخسارة. ننصحك بإعدادك وفقًا لحالة أصولك الفعلية للتحكم في المخاطر بشكل معقول.',
        p16: 'كيف تقلل من مخاطر العقد ؟',
        p17: 'يمكنك تحويل الأصول المتاحة من الحسابات المتبقية إلى حسابات العقود عن طريق تحويل الأموال ، أو تقليل المخاطر عن طريق تقليل حيازتك',
        p18: 'ماذا يعني عدد المراكز المفتوحة في العقد ؟',
        p19: 'يمثل عدد المراكز المفتوحة عدد العملات التي تتوقع شرائها. على سبيل المثال: حدد الصفحة الافتتاحية لزوج التداول BTC/USDT ، وافعل المزيد ، وسعر الإدخال هو 1000USDT ، وكمية الإدخال هي 10BTC ، وهي: تتوقع شراء 10BTC ، وسعر الوحدة هو 1000USDT.',
        p20: 'كيف يتم حساب رسوم المناولة في العقد ؟',
        p21: 'رسوم المناولة = سعر فتح المركز * عدد المراكز المفتوحة * معدل رسوم المناولة',
        p22: 'احتياطات التصفية الإجبارية',
        p23: 'مستوى المخاطرة هو مقياس لمخاطر الأصول الخاصة بك. عندما يكون الخطر يساوي 100 ٪ ، يتم اعتبار مركزك مغلبًا ، ودرجة المخاطرة = (الاحتفاظ بهامش/حقوق ملكية حساب العقد) * 100 ٪. من أجل منع المستخدمين من اختراق المراكز ، يتم تعيين النظام لضبط نسبة المخاطر. عندما تصل درجة المخاطر إلى قيمة المخاطر التي حددها النظام ، سيفرض النظام تصفية المركز. على سبيل المثال: يتم ضبط إعدادات النظام بنسبة 10 ٪ ، ثم عندما يكون الخطر أكبر من أو يساوي 90 ٪ ، سيتم إغلاق جميع مراكزك بالقوة من قبل النظام.',
        p24: 'ما هي قواعد تداول العقود ؟',
        p25: 'أنواع المعاملات',
        p26: 'تنقسم أنواع التداول إلى اتجاهين: الشراء الطويل (الشراء) والقصير (البيع):',
        p27: 'شراء الثيران (صعودي) يعني أنك تعتقد أن المؤشر الحالي لديه القدرة على الارتفاع ، وتريد شراء عدد معين من عدد معين من العقود للسعر الذي حددته أو سعر السوق الحالي.',
        p28: 'البيع على المكشوف (الهابط) هو عندما تعتقد أن المؤشر الحالي قد ينخفض وترغب في بيع عدد معين من العقود الجديدة بالسعر الذي حددته أو سعر السوق الحالي.',
        p29: 'طريقة الطلب',
        p30: 'طلب الحد: تحتاج إلى تحديد سعر وكمية الطلب',
        p31: 'أمر سعر السوق: ما عليك سوى تعيين عدد الطلبات ، والسعر هو سعر السوق الحالي',
        p32: 'الاحتفاظ بالمراكز',
        p33: 'عندما يتم الانتهاء من أمر فتح المركز الذي قدمته ، يطلق عليه الموقف',
        p34: 'قضايا تسليم العقود',
        p35: 'عقد المنصة هو عقد مستدام ولا يوجد وقت للتسليم. طالما أن النظام لا يفي بشروط المستوى القوي أو لم تقم بتصفية مركزه يدويًا ، يمكنك الاحتفاظ بالمراكز بشكل دائم. إغلاق النظام',
        p36: '1: الوصول إلى قيمة ضبط الربح ووقف الخسارة ، يقوم النظام تلقائيًا بتصفية المركز',
        p37: '2: المخاطر عالية جدا ، إغلاق النظام القسري',
        p38: 'ما هو هامش تداول العقود ؟',
        p39: 'في معاملات العقود ، يمكن للمستخدمين المشاركة في شراء وبيع العقود وفقًا لسعر العقد وكميته ، بالإضافة إلى مضاعفات الرافعة المالية المقابلة. سيشغل المستخدمون الهامش عند فتح المركز. كلما زاد هامش فتح المركز ، زادت مخاطر الحساب.',
        p40: 'ما هي أوامر تحديد الأسعار وأوامر السوق ؟',
        p41: 'تشير قائمة حد السعر إلى السعر الذي تتوقع أن يتم تكليفه بالمنصة للتداول ، وتشير قائمة سعر السوق إلى النظام الأساسي المفوض مباشرة للتداول بالسعر الحالي. في قواعد فتح المراكز ، يتم إعطاء أوامر سعر السوق الأولوية على أوامر تحديد الأسعار. إذا اخترت أمر حد السعر ، فيرجى فتح مركز معقول وفقًا لسعر السوق الحالي للعملة لتجنب الخسائر الناجمة عن سعر فتح المركز غير المعقول.',
        p42: 'ماذا يمثل مستوى المخاطرة في تداول العقود ؟',
        p43: 'المخاطر هي مؤشر المخاطر في حساب العقد الخاص بك. درجة المخاطرة تساوي 100 ٪ تعتبر تصفية. نقترح أنه عندما تكون مخاطرك أكثر من 50 ٪ ، يجب عليك فتح المراكز بعناية لتجنب الخسائر الناجمة عن التصفية. يمكنك تقليل المخاطر عن طريق تجديد الأموال المتاحة لأصول العقد أو تخفيف مراكزها.',
        p44: 'لماذا تحتاج إلى صرف العملات ؟',
        p45: 'الغرض من البورصة هو السماح للأموال بعملات مختلفة في أصولك بالتداول بسلاسة ، ويمكن تحويل QCC التي تم الحصول عليها في حساب العقود الآجلة بحرية إلى USDT للتداول. يمكن أيضًا تحويل USDT في حسابات أخرى بحرية إلى QCC للتداول.',
        p46: 'لماذا لم يتلق الطرف الآخر التحويل في الوقت المناسب بعد التحويل ؟',
        p47: 'أولاً ، تأكد من أن طريقة الدفع التي تملأها عند تحويل الأموال هي نفسها تمامًا كما تظهر في صفحة تفاصيل الطلب. ثم تأكد من أن طريقة الدفع الخاصة بك هي الدفع الفوري أو المتأخر. أخيرًا ، اتصل بالبنك ومؤسسة الدفع الخاصة بك لصيانة النظام أو لأسباب أخرى.',
        p48: 'كيفية شراء USDT من خلال النظام الأساسي ؟',
        p49: 'الطريقة الأولى: حدد الطلب الذي ترغب في شرائه من خلال قائمة شراء وبيع النظام الأساسي.',
        p50: 'الطريقة الثانية: اضغط على زر النشر لإصدار أمر شراء أو بيع وفقًا لحاجتك للمعاملات السريعة. سيقوم النظام بمطابقة المستخدمين الذين يشترون ويبيعون لك بسرعة.',
        p51: 'ملاحظة: إذا لم يتم دفع أمر الشراء بعد 15 دقيقة ، فسيتم إلغاء الطلب تلقائيًا ، يرجى الدفع في الوقت المناسب. تم إلغاء الطلب أكثر من 3 مرات في نفس اليوم ، ولا يمكن تداوله مرة أخرى في نفس اليوم ، وسيتم استعادة سلطة المعاملة في اليوم التالي.'
    },
    public: {
        zxkf: 'خدمة العملاء عبر الإنترنت',
        sure: 'تأكيد',
        cancel: 'إلغاء',
        RefreshSucceeded: 'نجاح التحديث',
        noMoreData: 'لا مزيد من البيانات',
        pullingText: 'اسحب للتحديث...',
        ReleaseText: 'الافراج عن تحديث...',
        loadingText: 'تحميل في...',
        noData: 'لا يوجد سجل',
        xq: 'التفاصيل',
        submit: 'تأكيد',
        kefu: 'خدمة العملاء عبر الإنترنت'
    },
    ChangeType: {
        Upper: 'إعادة شحن النظام',
        Lower: 'سحب النظام',
        Win: 'الربح',
        Bet: 'الاستثمارات',
        BuyMining: 'التعدين',
        CoinWin: 'أرباح العملة الجديدة',
        SettleMining: 'عودة رأس مال التعدين',
        GiveInterest: 'عائدات التعدين',
        BuyCoin: 'لم يتم الفوز بالعملة الجديدة',
        BuyCoinBack: 'عودة شراء العملة الجديدة',
        UsdtToBtc: 'Usdt إلى Btc',
        UsdtToEth: 'Usdt إلى Eth',
        BtcToUsdt: 'Btc إلى Usdt',
        BtcToEth: 'Btc إلى Eth',
        EthToUsdt: 'Eth إلى Usdt',
        EthToBtc: 'Eth إلى Btc',
        CoinFrozen: 'تجميد توقيع العملة الجديدة',
        CoinThaw: 'إذابة العملة الجديدة',
        CoinCancel: 'إلغاء أمر العملة',
        CoinBuy: 'شراء العملة',
        CoinBuyEntrust: 'عمولة شراء العملة',
        CoinSellEntrust: 'عمولة بيع العملات المعدنية',
        CoinSell: 'بيع العملات المعدنية',
        ContractBuy: 'طلب العقد ، خصم الهامش',
        ContractCharge: 'طلب العقد ، خصم رسوم المناولة',
        ContractCancelToBond: 'إلغاء العقد وإيداع الاسترداد',
        ContractCancelToCharge: 'رسوم استرداد إلغاء العقد',
        ContractCloseProfit: 'دخل إغلاق العقد'
    },
    trade: {
        title: 'عملات معدنية',
        Buy: 'شراء',
        Sell: 'بيع',
        pc: 'التصفية',
        cb: 'التكلفة',
        xj1: 'السعر الحالي',
        yk: 'الربح والخسارة',
        xianjia: 'حد السعر',
        jiage: 'السعر',
        shuliang: 'الكمية',
        keyong: 'متاح',
        yonghuchicang: 'مواقف المستخدمين',
        fxlts: 'معدل المخاطرة = هامش الاحتفاظ بالموقف/حقوق ملكية العميل مضروبًا في 100 ٪ ، حيث تساوي حقوق العميل الأموال المتاحة في الحساب ، هامش التداول الأولي ، الربح والخسارة العائم',
        fxl: 'معدل المخاطر',
        dqwt: 'التكليف الحالي',
        all: 'كل شيء',
        name: 'اسم',
        shijian: 'الوقت',
        zyjg: 'التداول بأفضل الأسعار الحالية',
        caozuo: 'العملية',
        xj: 'حد السعر',
        cc: 'الاحتفاظ بالمراكز',
        leixing: 'نوع',
        long: 'القيام بالمزيد',
        short: 'البيع على المكشوف',
        sj: 'سعر السوق',
        jiaoyie: 'حجم المعاملات',
        orderNo: 'رقم الطلب',
        weituojia: 'سعر العمولة',
        chengjiaojia: 'سعر الصفقة',
        chengjiaoe: 'حجم التداول',
        weituo: 'طريقة التكليف',
        qbwt: 'كل التفويض',
        lswt: 'التكليف التاريخي',
        tips: 'يرجى إدخال السعر الصحيح',
        tips1: 'الرجاء إدخال الكمية',
        Create: 'التكليف',
        Wait: 'التداول',
        Complete: 'تم إغلاق المركز',
        Cancel: 'تم إلغاء الطلب',
        zsj: 'سعر وقف الخسارة',
        zyj: 'سعر جني الأرباح',
        cczsy: 'إجمالي الدخل من المركز',
        ykbl: 'نسبة الربح والخسارة',
        bili: 'النسبة المئوية',
        jyss: 'عدد الصفقات',
        bzj: 'الهامش',
        sxf: 'رسوم المناولة',
        gg: 'مضاعفات الرافعة المالية',
        yjpc: 'مفتاح واحد',
        status: 'مركز',
    },
    auth: {
        title: 'مصادقة الهوية',
        title1: 'مصادقة الهوية',
        low: 'شهادة المبتدئين',
        high: 'شهادة متقدمة',
        success: 'نجاح الشهادة',
        tips: 'يرجى إجراء الشهادة الأولية أولاً',
        tips1: 'من فضلك أدخل اسمك الحقيقي',
        tips2: 'الرجاء إدخال رقم الهوية',
        tips3: 'يرجى تحميل صورة أمامية لبطاقة الهوية الخاصة بك',
        tips4: 'يرجى تحميل صورة على ظهر بطاقة الهوية الخاصة بك',
        tips5: 'يرجى تحميل الصور المحمولة',
        qrz: 'اذهب إلى الشهادة',
        shz: 'قيد المراجعة',
        rzsb: 'فشل الشهادة',
        userName: 'الاسم الحقيقي',
        IDNumber: 'رقم الهوية',
        zmz: 'صورة أمامية لبطاقة الهوية',
        bmz: 'صورة على ظهر بطاقة الهوية',
        scz: 'صورة محمولة باليد'
    },
    share: {
        title: 'رمز الترويج الخاص بي',
        cp: 'نسخ العنوان',
        success: 'نجاح النسخ'
    },
    property: {
        Contract: 'عملات معدنية',
        Exchange: 'عقد الثانية',
        zzc: 'إجمالي الأصول',
        mhyzh: 'حساب العقد الثاني',
        bbzh: 'حساب العملة',
        available: 'متاح',
        OnOrders: 'تجميد',
        Estimated: 'تحويل',
        cw: 'السجلات المالية',
        number: 'الكمية',
        record: 'سجل',
        Transfer: 'النقل',
        time: 'الوقت',
        dhsl: 'كمية الصرف',
        tips: 'يرجى إدخال كمية الاسترداد',
        qbdh: 'جميع الصرف',
        dhhl: 'سعر الصرف',
        ky: 'متاح',
        kd: 'متاح',
        sxfbl: 'نسبة رسوم المناولة',
        sxf: 'رسوم المناولة'
    },
    statusLang: {
        Review: 'مراجعة',
        Success: 'النجاح',
        Fail: 'فشل'
    },
    with: {
        title: 'السحب',
        history: 'سجلات السحب',
        ldz: 'عنوان السلسلة',
        tbdz: 'عنوان العملة',
        tips: 'يرجى إدخال عنوان العملة',
        tips1: 'يرجى إدخال كمية العملة',
        tips2: 'الرجاء إدخال كلمة مرور الدفع',
        tbsl: 'كمية العملة',
        ky: 'متاح',
        zjmm: 'دفع كلمة المرور',
        sxf: 'رسوم المناولة',
        dz: 'الحجم المتوقع للاستلام'
    },
    tabBar: {
        home: 'الصفحة الرئيسية',
        Quotation: 'اقتباسات',
        Quotation2: 'أسعار السوق',
        Trade: 'عملات معدنية',
        Contract: 'عقد الثانية',
        assets: 'الأصول',
        qhyy: 'تبديل اللغات',
        zyjb: 'تخزين ذكي',
        jsjy: 'التداول السريع',
        jryw: 'العمليات المالية',
        hyjy: 'معاملات العقود',
        bbjy: 'التداول بالعملات'
    },
    home: {
        UserName: 'الحسابات',
        kjmb: 'شراء العملات بسرعة',
        score: 'نقاط الائتمان',
        zc: 'دعم BTC ، USDT ، إلخ.',
        scwk: 'تخزين ذكي',
        sgxb: 'شراء العملة الجديدة',
        aqkx: 'آمنة وذات مصداقية',
        aqkx1: 'منصة تداول الأصول الرقمية الرائدة في العالم',
        zfb: 'قائمة الزيادة',
        dfb: 'انخفاض قائمة',
        mc: 'اسم',
        hy: 'العقد',
        zxj: 'أحدث سعر',
        zdb: 'صعود وهبوط',
        sfyz: 'مصادقة الهوية',
        skfs: 'طريقة التحصيل',
        share: 'رابط المشاركة الخاص بي',
        kjcb: 'الشحن السريع',
        kjtb: 'عملة سريعة',
        qbdz: 'عنوان المحفظة',
        bps: 'ورقة بيضاء',
        xgmm: 'تعديل كلمة المرور',
        xgzfmm: 'تعديل كلمة مرور الدفع',
        gywm: 'عن نحن',
        tcdl: 'الخروج'
    },
    quotation: {
        title: 'اقتباسات',
        mc: 'الكمية',
        zxj: 'أحدث سعر',
        zdb: 'صعود وهبوط',
        liang: 'الكمية'
    },
    contract: {
        kcslfw: 'نطاق حجم فتح المركز:',
        gao: 'عالية',
        di: 'منخفض',
        liang: 'حجم 24H',
        placeholder: 'الرجاء إدخال الكمية',
        SuccessfulPurchase: 'نجاح الشراء',
        smallMin: 'دقيقة',
        hour: 'ساعات',
        dayMin: 'يا إلهي',
        Trend: 'تقاسم الوقت',
        ccjl: 'قائمة المراكز',
        ddjl: 'سجل الطلب',
        sl: 'الكمية',
        gmj: 'سعر الشراء',
        dqj: 'السعر الحالي',
        yjyk: 'الربح والخسارة المتوقعة',
        djs: 'العد التنازلي',
        gbjg: 'سعر الصفقة',
        sc: 'رسوم المناولة',
        pal: 'الربح والخسارة',
        mhy: 'عقد الثانية',
        sj: 'الوقت',
        yll: 'معدل الربح',
        mz: 'شراء',
        md: 'شراء السقوط',
        kcsl: 'عدد المراكز المفتوحة',
        kcsj: 'وقت فتح المركز',
        zhye: 'رصيد الحساب',
        qc: 'إلغاء'
    },
    editPassword: {
        title: 'تعديل كلمة المرور',
        jmm: 'كلمة المرور القديمة',
        xmm: 'كلمة المرور الجديدة',
        qrmm: 'تأكيد كلمة المرور',
        placeholder: 'كلمة المرور القديمة',
        placeholder1: 'تعيين كلمة مرور جديدة',
        placeholder2: 'الرجاء إعادة إدخال كلمة المرور الجديدة',
        qrxf: 'تأكيد'
    },
    withPass: {
        title: 'كلمة مرور السحب',
        jmm: 'كلمة المرور القديمة',
        xmm: 'كلمة المرور الجديدة',
        qrmm: 'تأكيد كلمة المرور',
        placeholder: 'كلمة المرور القديمة',
        placeholder1: 'تعيين كلمة مرور جديدة',
        placeholder2: 'الرجاء إعادة إدخال كلمة المرور الجديدة',
        qrxf: 'تأكيد',
        tips: 'يرجى إدخال كلمة مرور قديمة لا تقل عن 6 أرقام',
        tips1: 'الرجاء إدخال كلمة مرور جديدة لا تقل عن 6 أرقام',
        tips2: 'الرجاء إدخال كلمة المرور الصحيحة للتأكيد',
        tips3: 'كلمة مرور جديدة غير متسقة تم إدخالها مرتين',
        tips4: 'تعيين كلمة المرور بنجاح',
        confirm: 'تأكيد'
    },
    newBe: {
        title: 'شراء العملة الجديدة',
        title1: 'الاشتراك الخاص بي',
        scqx: 'فترة قفل المستودع',
        day: 'يا إلهي',
        sy: 'الفائض',
        sdsg: 'سجل',
        sysl: 'الكمية المتبقية',
        gmsl: 'شراء الكمية',
        dj: 'سعر الوحدة',
        tips: 'يرجى إدخال كمية الشراء',
        gmzj: 'السعر الإجمالي للشراء',
        qbye: 'رصيد المحفظة',
        fmzj: 'السعر الإجمالي للشراء',
        gm: 'شراء',
        sgsj: 'وقت الاشتراك',
        Currency: 'العملة',
        Request: 'عدد الطلبات',
        Passing: 'من خلال الكمية',
        MarketTime: 'وقت السوق',
        sqz: 'في التطبيق'
    },
    register: {
        title: 'التسجيل',
        lang: 'اللغات',
        send: 'إرسال',
        Resend: 'إعادة إرسال',
        Account: 'الحسابات',
        Password: 'كلمة السر',
        ConfirmPassword: 'تأكيد كلمة المرور',
        InvitationCode: 'رمز الدعوة',
        btn: 'التسجيل',
        amount: 'رقم الحساب',
        mailbox: 'صندوق البريد',
        haveAccount: 'هل لديك حساب ؟',
        Login: 'تسجيل الدخول',
        placeholder: 'الرجاء إدخال البريد الإلكتروني',
        placeholder0: 'الرجاء إدخال الحساب',
        placeholder1: 'الرجاء إدخال كلمة السر',
        placeholder2: 'الرجاء إدخال كلمة مرور للتأكيد',
        placeholder3: 'الرجاء إدخال رمز الدعوة',
        placeholder4: 'الرجاء إدخال رمز التحقق في البريد الإلكتروني',
        placeholder5: 'الرجاء إدخال صندوق البريد الصحيح',
        success: 'تسجيل ناجح',
        message: 'كلمة المرور 6 أرقام على الأقل',
        message1: 'تأكيد كلمة المرور 6 أرقام على الأقل',
        message2: 'لا تتطابق كلمة المرور مرتين',
        message3: 'يرجى قراءة الاتفاقية ووضع علامة عليها',
        agree: 'أنا موافق',
        xy: 'اتفاقية المستخدم وسياسة الخصوصية',
        qr: 'تأكيد'
    },
    lockming: {
        title: 'قائمة القفل',
        wkb: 'حفر كنز التعدين',
        wkgjd: 'التعدين أبسط',
        qsljl: 'من السهل الحصول على المكافآت',
        yjzsr: 'مجموع الإيرادات المتوقعة',
        ck: 'السنوات المرجعية في 3 أيام الماضية',
        tips: 'على الأقل',
        rlx: 'الفائدة على اليوم',
        day: 'يا إلهي',
        scqx: 'فترة قفل المستودع',
        buy: 'شراء',
        zysl: 'كمية الرهن',
        qbye: 'رصيد المحفظة',
        finished: 'الانتهاء',
        doing: 'جارية...',
        sl: 'الكمية',
        shouyilv: 'معدل العائد',
        ks: 'وقت البدء',
        js: 'نهاية الوقت'
    },
    login: {
        title: 'تسجيل الدخول',
        lang: 'اللغات',
        Account: 'الحسابات',
        Password: 'كلمة السر',
        btn: 'تسجيل الدخول',
        placeholder1: 'الرجاء إدخال كلمة السر',
        placeholder: 'الرجاء إدخال الحساب',
        fail: 'يرجى ملء النموذج الكامل',
        ForgotPassword: 'نسيت كلمة المرور',
        NoAccount: 'ليس لديك حساب حتى الآن ؟',
        Registration: 'التسجيل',
        server: 'خدمة العملاء عبر الإنترنت',
        jzmm: 'تذكر كلمة المرور',
        success: 'تسجيل الدخول بنجاح',
        Home: 'الصفحة الرئيسية'
    },
    moneyAddr: {
        title: 'عنوان المحفظة',
        Currency: 'العملة',
        Address: 'العنوان',
        placeholder: 'يرجى اختيار',
        DepositAddress: 'عنوان شحن العملة',
        WalletAddress: 'عنوان المحفظة',
        Date: 'الوقت',
        add: 'إضافة عنوان المحفظة',
        ChainAddress: 'عنوان السلسلة',
        qrcode: 'رمز ثنائي الأبعاد للمحفظة',
        submit: 'تأكيد',
        tips: 'يرجى اختيار نوع المحفظة',
        tips1: 'يرجى اختيار نوع عنوان السلسلة',
        tips2: 'الرجاء إدخال عنوان المحفظة',
        tips3: 'يرجى تحميل رمز الاستجابة السريعة للمحفظة',
        success: 'النجاح'
    },
    recharge: {
        title: 'إعادة الشحن',
        title1: 'تفاصيل إعادة الشحن',
        title2: 'تقديم تفاصيل',
        ls: 'تفاصيل إعادة الشحن',
        td: 'يرجى اختيار قناة إعادة الشحن التالية',
        bc: 'حفظ رمز الاستجابة السريعة',
        address: 'عنوان شحن العملة',
        copy: 'نسخ العنوان',
        num: 'كمية الشحن',
        Upload: 'تحميل لقطة شاشة تفاصيل الدفع',
        Submit: 'تأكيد',
        tip: 'الرجاء إدخال الكمية',
        tip1: 'يرجى تحميل لقطة شاشة',
        copySuc: 'نجاح النسخ'
    },
    newChange: {
        num: 'الرجاء إدخال الكمية',
        psd: 'الرجاء إدخال كلمة السر',
        hq: 'اقتباسات',
        bb: 'مركز المساعدة',
        sec: 'شهادة الاسم الحقيقي',
        ass: 'الأصول',
        pool: 'الإدارة المالية',
        newc: 'عن نحن',
        rechange: 'إعادة الشحن',
        with: 'خدمة العملاء',
        help: 'مركز المساعدة',
        a1: 'المالية',
        a2: 'الأموال المحتجز (USDT)',
        a3: 'أرباح اليوم',
        a4: 'الأرباح التراكمية',
        a5: 'قيد العمل',
        a6: 'أوامر',
        a7: 'القواعد',
        a8: 'العائد اليومي',
        a9: 'حد واحد',
        a10: 'دورة',
        a11: 'Pecently (الدخل اليومي)',
        a12: 'القيود الفردية',
        a13: 'عدد الاستضافة:',
        a14: 'الاشتراك',
        a15: 'حساب العائد',
        a16: 'يتم نقل الطوب من خلال استضافة USDT إلى المنصة ، حيث يوجد فريق محترف للمراجحة...',
        a17: 'اشترى أحد الأعضاء 10000U على المنصة واختار منتجًا لإدارة الثروات لمدة 5 أيام ومعدل عائد يومي من 0.3 ٪ إلى 0.5 ٪. الحد الأقصى اليومي: 10000U × 0.5 ٪ = 50 U n ، أي بعد 5 أيام ، يمكن الحصول على دخل 150U-250U ، ويتم إصدار الدخل يوميًا ، ويمكن استخدام الدخل الصادر في أي وقت. انتهت صلاحية رأس المال المودعة ، وبعد ذلك سيكون...',
        a18: 'حول الأضرار المصفاة',
        a19: 'إذا كنت ترغب في نقل أصل غير منتهي الصلاحية ، فستكون هناك تعويضات منتهية الصلاحية. التعويضات المقطوعة = نسبة التخلف عن السداد * الأيام المتبقية * عدد الاستثمارات. مثال N: نسبة التسوية الافتراضية للمنتج هي 0.4 ٪ ، والأيام الثلاثة المتبقية مستحقة ، ومبلغ الاستثمار 1000 ، والأضرار المقطوعة = 0.4 ٪ * 3...',
        a20: 'أريد الإنضمام'
    },
    msgbank: {
        msgTitle: ' رسالة ',
        noticeTitle: 'إعلان',
        msgContent: 'لديك رسالة غير مقروءة',
        msgBtn: 'عرض',
        bankCard: 'بطاقة مصرفية',
        inputTips: 'يرجى إدخال معلومات كاملة',
        submitSuccess: 'يقدم بنجاح',
        CardUserName: 'اسم العميل',
        BankName: 'فتح حساب مصرفي',
        OpenBank: 'فتح حساب صافي',
        CardNo: 'رقم بطاقة البنك',
        noBindBankmsg: 'لم يكن لديك بطاقة البنك ملزمة',
        noBindBankBtn: 'ربط',
        modify: 'عدل',
    }
}